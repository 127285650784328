import { Col, Row } from '../../../components/Grid';
import * as T from '../../../components/Typography';
import { BasicButton } from '../../../components/Button';
import Modal from '../../../components/Modal';

const ExistingEmailConsentModal = ({
  isEditingConfirmed,
  email,
  isModalVisible,
  setIsModalVisible,
  handleSubmit,
}) => {
  return (
    <Modal
      visible={isModalVisible.show}
      setIsModalVisible={() =>
        setIsModalVisible({ show: false, organisationNames: [] })
      }
      showCloseIcon
    >
      <Row>
        <Col w={[4, 12, 12]}>
          <T.P size="med" color="white" mt={4} mb={4}>
            <u>{email}</u> email is already managing{' '}
            <u>{isModalVisible.organisationNames.join(', ')}</u> organisation
            {isModalVisible.organisationNames?.length > 1 && 's'}.
          </T.P>
          <T.P size="regular" weight="bold" color="white">
            Please confirm that you would like to add{' '}
            {isEditingConfirmed ? ' this' : ' the new '} organisation to this
            user.
          </T.P>
          <BasicButton
            variant="secondary"
            onClick={(e) => handleSubmit(e, isEditingConfirmed, true)}
            mt={4}
          >
            Confirm
          </BasicButton>
        </Col>
      </Row>
    </Modal>
  );
};

export default ExistingEmailConsentModal;
