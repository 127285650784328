import styled from '@emotion/styled';

export const Wrapper = styled.article`
  display: flex;
  height: calc(var(--vh, 1vh) * 100);
  background: ${({ theme, bgColor }) =>
    bgColor ? theme.colors[bgColor] || bgColor : 'transparent'};
`;

export const Content = styled.main`
  width: 100%;
  position: relative;
  overflow: hidden;
  flex-direction: column;
  display: flex;
`;

export const ContentBody = styled.div`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  max-width: min(100%, 720px);
  margin: 0 auto;
  flex-grow: 1;
  flex-direction: column;
  display: flex;
  padding-bottom: ${({ theme }) => theme.spacings[6]};

  ${({ theme }) => theme.media.tablet} {
    min-height: ${({ theme, headerType }) =>
      `calc(100vh - ${
        headerType === 'progress'
          ? theme.constants.layout.calculateHeight('tabletWithProgressBar')
          : theme.constants.layout.calculateHeight('tabletWithoutProgressBar')
      } )`};
  }

  ${({ theme }) => theme.media.mobile} {
    max-width: min(100%, 378px);
    min-height: ${({ theme, headerType }) =>
      `calc(100vh - ${
        headerType === 'progress'
          ? theme.constants.layout.calculateHeight('mobileWithProgressBar')
          : theme.constants.layout.calculateHeight('mobileWithoutProgressBar')
      }) `};

    padding: ${({ theme: { spacings }, theme, headerType }) => {
      return headerType === 'progress'
        ? `${theme.constants.layout.calculateMargin('progressBarMobile')} ${
            spacings[5]
          } ${spacings[6]} ${spacings[5]}`
        : `${theme.constants.layout.calculateMargin('noProgressBarMobile')} ${
            spacings[5]
          } ${spacings[6]} ${spacings[5]}`;
    }};
  }

  padding: ${({ theme: { spacings }, theme, headerType }) => {
    return headerType === 'progress'
      ? `${theme.constants.layout.calculateMargin('progressBar')} ${
          spacings[5]
        } ${spacings[6]} ${spacings[5]}`
      : `${theme.constants.layout.calculateMargin('noProgressBar')} ${
          spacings[5]
        } ${spacings[6]} ${spacings[5]}`;
  }};
`;
