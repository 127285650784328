import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useAuth } from '../../../context/auth';
import { navRoutes } from './../../../constants';
import { Col, Row } from '../../../components/Grid';
import { BasicButton } from '../../../components/Button';
import StartCoaching from '../../../components/StartCoaching';
import Modal from '../../../components/Modal';
import InfoCard from '../../../components/InfoCard';
import * as T from '../../../components/Typography';
import * as S from '../Dashboard/style';
import useCustomTrainingStepHook from '../Training/Hooks/useCustomTrainingStepHook';
import { CopyLink, Loading, OnboardingModal, StatsChart } from 'components';
import {
  useGetCoachDetails,
  useGetCoachStuckAtLearners,
  useUpdateCoach,
} from 'api-calls/coaches.queries';
import { useGetLearners } from 'api-calls/learners.queries';
import { COACH, COMMON } from 'constants/nav-routes';
import { JoinOurCommunity } from 'components/JoinOurCommunity';
import TrainingBanner from 'components/TrainingBanner';
import { getGreetings } from 'helpers';
import { Stack } from '@mui/material';
import theme from 'theme';
import { getOnboardingCoachStaticData } from 'components/OnboardingModal/onboardingModalStaticData';
import FeedbackModal from '../../../components/FeedbackModal';
import NewLearners from './NewLearners';
import GenericCommonTable from 'components/CommonTables/GenericCommonTable';
import Tag from 'components/Tag';
import { useGetLearnerCoachSessionByCoachId } from 'api-calls/learner-coach-sessions.queries';
import { useSiteSettings } from 'context/site-settings';

const Dashboard = () => {
  const { courseName, courseCode } = useSiteSettings();
  const { learners = [], isLoading: isLearnersLoading } = useGetLearners();
  const { learnersStuckAt, isLoading: isStuckAtLoading } =
    useGetCoachStuckAtLearners();
  const { user } = useAuth();

  const [modalClicked, setModalClicked] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [page, setPage] = useState(1);
  const { coach, isLoading: isCoachLoading } = useGetCoachDetails({
    coachId: user.id,
  });

  const {
    data: sessions,
    error: getSessionsError,
    isLoading: getSessionsLoading,
  } = useGetLearnerCoachSessionByCoachId();
  const { mutate } = useUpdateCoach(user.id);
  const { activeStep, trainingContent, isAllStepCompleted, isAPILoading } =
    useCustomTrainingStepHook();

  const activeStepData = trainingContent[activeStep];

  const currentLearners = learners?.filter((e) => e); // .onboarded && e.confirmed
  const toOnboardLearners = learners?.filter(
    (e) => !e.onboarded && e.confirmed
  );
  const newLearners = learners?.filter((e) => !e.confirmed);

  const stuckLearners = learnersStuckAt?.reduce((acc, curr) => {
    if (!acc.find((item) => item.learnerId === curr.learnerId)) {
      acc.push(curr);
    }
    return acc;
  }, []);

  const storeModalIntoStorage = () => {
    localStorage.setItem('modal', JSON.stringify(true));
    setModalClicked(true);
  };

  useEffect(() => {
    const _modal = localStorage.getItem('modal');
    setModalClicked(_modal);
  }, [modalClicked]);

  useEffect(() => {
    if (coach && !coach?.onboarded) {
      setShowOnboarding(true);
    }
  }, [coach]);

  useEffect(() => {
    if (isModalVisible) {
      let root = document.getElementById('root');
      root?.setAttribute('aria-hidden', 'false');
    }
  }, [isModalVisible]);

  const handleCancel = () => {
    storeModalIntoStorage();
    setIsModalVisible(false);
  };

  const closeOnboarding = useCallback(() => {
    setShowOnboarding(false);
  }, []);

  const updateCoachOnboarded = useCallback(() => {
    closeOnboarding();
    mutate({
      coachId: user.id,
      onboarded: true,
    });
  }, [user, mutate, closeOnboarding]);

  const addMarginToCommunity =
    !!stuckLearners?.length ||
    !!newLearners?.length ||
    !!toOnboardLearners?.length;

  if (isAPILoading || isCoachLoading || isLearnersLoading || isStuckAtLoading) {
    return <Loading />;
  }

  const columns = [
    // {
    //   title: '#',
    //   dataIndex: 'id',
    //   key: 'id',
    //   render: (text, record, index) => (
    //     <T.P style={{ whiteSpace: 'nowrap' }}>{index + 1}</T.P>
    //   ),
    // },
    {
      title: 'Session Date',
      dataIndex: 'start',
      key: 'start',
      render: (start) => (
        <T.P style={{ whiteSpace: 'nowrap' }}>
          {moment(start).format('DD MMM YYYY')}
        </T.P>
      ),
    },
    {
      title: 'Time',
      dataIndex: 'start',
      key: 'start',
      render: (start) => (
        <T.P style={{ whiteSpace: 'nowrap' }}>
          {moment(start).format('hh:mm A')}
        </T.P>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        return (
          <div>
            {status === 'PENDING' ? (
              <Tag color="quinaryDark">
                <T.P color="white" weight="bold">
                  Pending
                </T.P>
              </Tag>
            ) : status === 'CANCELLED' ? (
              <Tag color="quaternaryMainHex">
                <T.P color="white" weight="bold">
                  Cancelled
                </T.P>
              </Tag>
            ) : status === 'BOOKED' ? (
              <Tag color="quinaryDark">
                <T.P color="white" weight="bold">
                  Booked
                </T.P>
              </Tag>
            ) : null}
          </div>
        );
      },
      onCell: () => {
        return {
          style: { padding: '16px 6px' },
        };
      },
    },
    {
      title: 'Location',
      dataIndex: 'link',
      key: 'link',
      render: (_, row) => {
        const isOnline = row.type === 'ONLINE';

        let locationTxt = isOnline
          ? row.link
          : `${row.addressLine1}, ${row.town}, ${row.postcode}`;
        return (
          <CopyLink
            text={locationTxt}
            title={isOnline ? 'Copy Link' : 'Copy Address'}
          />
        );
      },
      onCell: () => {
        return {
          style: { padding: '16px 8px' },
        };
      },
    },
    {
      title: 'Learner',
      dataIndex: 'learnerFullName',
      key: 'learnerFullName',
      render: (text) => (
        <T.P
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '95px',
          }}
        >
          {text}
        </T.P>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <BasicButton
          variant="tertiary"
          icon="edit3"
          height="40px"
          width="120px"
          weight="normal"
          to={COACH.EDIT_COACHING_SESSION.replace(
            ':sessionIdP',
            record.id
          ).replace(':learnerIdP', record.learnerId)}
        >
          View/Edit
        </BasicButton>
      ),
      onCell: () => {
        return {
          style: { padding: '16px 2px' },
        };
      },
    },
  ];
  if (getSessionsError?.message) {
    return (
      <T.P mb={2} mt={2} color="error">
        {getSessionsError?.message}
      </T.P>
    );
  }
  return (
    <S.DashboardWrapper>
      <S.HeaderWrapper mb="7">
        <T.H1 id="welcome-message" color="neutralMain">
          {getGreetings()}, {user.firstName}!
        </T.H1>
        <StartCoaching />
      </S.HeaderWrapper>
      <S.ContentWrapper>
        <S.LeftColumn>
          {activeStepData ? (
            <TrainingBanner
              isAllStepCompleted={isAllStepCompleted}
              stepIndex={activeStep}
              stepData={activeStepData}
            />
          ) : null}
          <T.H2 mt="6" mb="3">
            Statistics
          </T.H2>
          <S.StatisticsWrapper>
            <StatsChart
              backgroundMode="light"
              statChartType="totalLearnersCount"
              value={coach?.totalLearners}
            />
            <StatsChart
              backgroundMode="dark"
              statChartType="milestone"
              value={coach?.completedMilestones || 0}
            />
            <StatsChart
              backgroundMode="light"
              statChartType="coachLearningTime"
              value={coach?.totalCoachingTime?.hours || 0}
            />
          </S.StatisticsWrapper>
          <S.HR my="32px" />
          <S.TableTitle>
            <T.H2 style={{ textWrap: 'nowrap' }}>Coaching Sessions</T.H2>
            <S.ButtonWrapper>
              <BasicButton
                icon="calendar"
                iconProps={{ height: '24px', width: '24px' }}
                variant="tertiary"
                to={navRoutes.COACH.CREATE_COACHING_SESSION}
                style={{ whiteSpace: 'nowrap' }}
                height="40px"
              >
                Arrange Session
              </BasicButton>
              <T.Link to={navRoutes.COACH.ALL_SESSIONS}>
                <T.H3
                  style={{
                    textWrap: 'nowrap',
                    textDecoration: 'underline',
                    whiteSpace: 'nowrap',
                    color: '#405ED6',
                    cursor: 'pointer',
                  }}
                >
                  All sessions
                </T.H3>
              </T.Link>
            </S.ButtonWrapper>
          </S.TableTitle>
          <S.TableWrapper>
            <GenericCommonTable
              columns={columns}
              dataSource={sessions}
              isResponsiveScroll
              pageSize={5}
              setPage={setPage}
              isLoading={getSessionsLoading}
              paginationDisabled={true}
              page={page}
              emptyState={{
                title: 'No coaching sessions found',
                subTitle: 'Click on arrange session to create a new session',
                buttonText: 'Arrange Session',
                buttonLink: navRoutes.COACH.CREATE_COACHING_SESSION,
              }}
            />
          </S.TableWrapper>
          {currentLearners?.length ? (
            <>
              <T.H2 mt="6" mb="3">
                Current learners
              </T.H2>
              {currentLearners?.slice(0, 4).map((learner, idx) => (
                <InfoCard
                  key={idx}
                  mb={idx === currentLearners.length - 1 ? '0' : '3'}
                  firstColumnLabel="Learner ID"
                  firstColumnValue={learner.learnerUniqueId}
                  secondColumnLabel="Name"
                  secondColumnValue={learner.firstName}
                  buttonText="View"
                  to={COMMON.LEARNER.replace(':userId', learner.id)}
                  hasNewMessages={learner.hasNewMessages}
                  messagingLink={navRoutes.COACH.CHAT_SAFEGUARD.replace(
                    ':receiverId',
                    learner.id
                  )}
                />
              ))}
            </>
          ) : null}
        </S.LeftColumn>
        <S.RightColumn>
          <Stack spacing={'24px'}>
            {stuckLearners?.length ? (
              <div>
                <Stack
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  direction={'row'}
                  marginBottom={'12px'}
                >
                  <T.H2>Stuck learners ({stuckLearners?.length})</T.H2>
                  {stuckLearners?.length > 4 && (
                    <T.Link
                      to={navRoutes.COACH.LEARNERS_STUCK_AT}
                      color={theme.colors.primaryMain}
                      underline
                    >
                      See All
                    </T.Link>
                  )}
                </Stack>
                {stuckLearners?.slice(0, 4)?.map((learner, idx) => (
                  <>
                    <InfoCard
                      key={idx}
                      mb={idx === stuckLearners.length - 1 ? '0' : '3'}
                      firstColumnLabel="Learner ID"
                      firstColumnValue={learner.learnerUniqueId}
                      secondColumnLabel="Name"
                      secondColumnValue={learner.firstName}
                      buttonText={'View'}
                      to={navRoutes.COMMON.LEARNER.replace(
                        ':userId',
                        learner.learnerId
                      )}
                      hasNewMessages={learner.hasNewMessages}
                      messagingLink={navRoutes.COACH.CHAT_SAFEGUARD.replace(
                        ':receiverId',
                        learner.learnerId
                      )}
                    />
                  </>
                ))}
              </div>
            ) : null}
            <NewLearners newLearners={newLearners} />
            {toOnboardLearners?.length ? (
              <div>
                <T.H2 mb="1">Learners to be onboarded</T.H2>
                {toOnboardLearners?.map((learner, idx) => (
                  <InfoCard
                    key={idx}
                    mb={idx === toOnboardLearners.length - 1 ? '0' : '3'}
                    firstColumnLabel="Learner ID"
                    firstColumnValue={learner.learnerUniqueId}
                    secondColumnLabel="Name"
                    secondColumnValue={learner.firstName}
                    buttonText={'Onboard'}
                    to={COACH.NEW_LEARNER.replace(
                      ':firstName',
                      learner.firstName
                    ).replace(':userId', learner.id)}
                    actionType={'onboard'}
                  />
                ))}
              </div>
            ) : null}

            <JoinOurCommunity column mt={addMarginToCommunity ? '5' : ''} />
          </Stack>
        </S.RightColumn>
      </S.ContentWrapper>

      <Modal
        visible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        onCancel={handleCancel}
      >
        <Row>
          <Col w={[4, 12, 12]} mb={4}>
            <T.H2 color="neutralSurface" weight="bold" mb={3}>
              You’ve finished training! You’re now ready to take on a learner
              and we will be in touch soon.
            </T.H2>
            <T.P color="neutralSurface" weight="regular">
              This might take a while but if there’s anything else you need in
              the meantime you can always contact Shannon Trust at{' '}
              <T.Link
                to={'mailto:scott.haines@shannontrust.org.uk'}
                // to={'mailto:chloe.bradbury@shannontrust.com'}
                external
                color="white"
                hoverColor="neutralMain"
                underline
              >
                scott.haines@shannontrust.org.uk
                {/* chloe.bradbury@shannontrust.com */}
              </T.Link>
            </T.P>
          </Col>
        </Row>

        <BasicButton
          handleClick={handleCancel}
          variant="secondary"
          id="back-to-dashboard"
          aria-label="back-to-dashboard"
        >
          <T.P color="white" weight="bold">
            Okay, got it!
          </T.P>
        </BasicButton>
      </Modal>

      <OnboardingModal
        isModalOpen={showOnboarding}
        onClose={closeOnboarding}
        handleClick={updateCoachOnboarded}
        data={getOnboardingCoachStaticData({ courseCode, courseName })}
      />

      <FeedbackModal />
    </S.DashboardWrapper>
  );
};

export default Dashboard;
