import { useEffect, useLayoutEffect } from 'react';
import { ThemeProvider } from '@emotion/react';
import { Global } from '@emotion/react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import theme, { globalStyle } from './theme';
import { Route as CustomRoute } from './components';
import * as Pages from './pages';
import AdminRouter from './pages/Admin';
import OrganisationAdminRouter from './pages/OrganisationAdmin';
import { navRoutes, userRoles } from './constants';
import { ScrollToTop } from './helpers';
import { AuthProvider } from './context/auth';
import { SpeechSynthesisProvider } from 'context/speech-synthesis';
import { GeneralStateProvider } from './context/general-state';
import { ProgressCheckProvider } from './context/progress-check';
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import CommonRouter from './pages/CommonPages';
import { OrganisationDetailsProvider } from './context/organisation-details';
import { SiteSettingsProvider } from 'context/site-settings';

import 'antd/dist/antd.min.css';
import RouteChangeTracker from './RouteChangeTracker';
import axios from 'axios';
const isProduction =
  process.env.NODE_ENV === 'production' && !process.env.REACT_APP_CIRCLE_CI;

// const domainGroupId = process.env.REACT_APP_COOKIEBOT_DOMAIN_ID;
const muiTheme = createTheme(theme);

function App({ ReactGA }) {
  useEffect(() => {
    localStorage.getItem('isLarge') === 'true'
      ? (document.getElementsByTagName('html')[0].style.fontSize = '1.1875rem')
      : (document.getElementsByTagName('html')[0].style.fontSize = '1rem');
  }, []);

  useLayoutEffect(() => {
    if (
      process.env.NODE_ENV === 'production' &&
      !process.env.REACT_APP_CIRCLE_CI &&
      process.env.REACT_APP_DOMAIN_NAME
    ) {
      const apiUrl = `https://api.${window.location.hostname}/api`;

      axios.defaults.baseURL = apiUrl;
      axios.defaults.withCredentials = true;
    } else {
      axios.defaults.baseURL = `${process.env.PUBLIC_URL}/api`;
      axios.defaults.withCredentials = true;

      // eslint-disable-next-line no-console
      console.log('process.env.PUBLIC_URL:', process.env.PUBLIC_URL);
    }
  }, []);

  return (
    <div className="app">
      <Global styles={globalStyle} />
      <ThemeProvider theme={theme}>
        <MuiThemeProvider theme={muiTheme}>
          <SiteSettingsProvider>
            <AuthProvider>
              <GeneralStateProvider>
                <SpeechSynthesisProvider>
                  <BrowserRouter>
                    <RouteChangeTracker
                      isProduction={isProduction}
                      ReactGA={ReactGA}
                    />
                    <ScrollToTop />
                    <Routes>
                      <Route
                        path={navRoutes.GENERAL.REDIRECTION_TO_TURNING_PAGES}
                        element={
                          <CustomRoute
                            Component={Pages.RedirectScreen}
                            layout="center"
                          />
                        }
                      />
                      <Route
                        path={navRoutes.GENERAL.HEADER_COMPONENT}
                        element={
                          <CustomRoute
                            Component={Pages.HeaderComponentPage}
                            image="secondaryColor"
                            publicOnly
                          />
                        }
                      />
                      <Route
                        path={navRoutes.GENERAL.LOGIN}
                        element={
                          <CustomRoute
                            Component={Pages.Login}
                            layout="center"
                            publicOnly
                          />
                        }
                      />
                      <Route
                        path={navRoutes.LEARNER.LOGIN}
                        element={
                          <CustomRoute
                            Component={Pages.Learner.Login}
                            layout="center"
                            publicOnly
                          />
                        }
                      />
                      <Route
                        path={navRoutes.LEARNER.SIGNUP}
                        element={
                          <CustomRoute
                            Component={Pages.Learner.Signup}
                            layout="center"
                            publicOnly
                            contentProps={{
                              maxWidth: '1038px',
                            }}
                          />
                        }
                      />
                      <Route
                        path={navRoutes.LEARNER.HAVE_ACCOUNT}
                        element={
                          <CustomRoute
                            Component={Pages.Learner.HaveAccount}
                            layout="center"
                            publicOnly
                            contentProps={{
                              maxWidth: '1038px',
                            }}
                          />
                        }
                      />
                      <Route
                        path={navRoutes.LEARNER.SIGNUP_EXISTING_ACCOUNT}
                        element={
                          <CustomRoute
                            Component={Pages.Learner.SignupExistingAccount}
                            layout="center"
                            publicOnly
                            contentProps={{
                              maxWidth: '1038px',
                            }}
                          />
                        }
                      />
                      <Route
                        path={navRoutes.GENERAL.FORGET_PASSWORD}
                        element={
                          <CustomRoute
                            Component={Pages.ForgetPassword}
                            layout="side"
                            image="secondaryColor"
                          />
                        }
                      />
                      <Route
                        path={navRoutes.LEARNER.LANDING_PAGE}
                        element={
                          <CustomRoute
                            Component={Pages.Learner.LandingPage}
                            layout="full"
                            contentBodyProps={{
                              noPadding: true,
                              maxWidth: true,
                            }}
                          />
                        }
                      />
                      <Route
                        path={navRoutes.GENERAL.RESET_PASSWORD}
                        element={
                          <CustomRoute
                            Component={Pages.ResetPassword}
                            layout="side"
                            image="secondaryColor"
                          />
                        }
                      />

                      <Route
                        path="/"
                        element={
                          <CustomRoute.Redirect
                            path="/"
                            to={navRoutes.LEARNER.LOGIN}
                          />
                        }
                      />

                      <Route element={<OrganisationDetailsProvider />}>
                        {/* route for REFER_A_LEARNER_DEFAULT, to redirect to '/hello'  */}
                        <Route
                          path={navRoutes.GENERAL.REFER_A_LEARNER_DEFAULT}
                          element={
                            <Navigate to="/shannon-trust-moj/refer-a-learner" />
                          }
                        />

                        <Route
                          path={navRoutes.GENERAL.REFER_A_LEARNER}
                          element={
                            <CustomRoute
                              Component={Pages.ReferLearner}
                              layout="side"
                              sideChildren={<Pages.ReferLearner.SideDetails />}
                            />
                          }
                        />
                        <Route
                          path={navRoutes.GENERAL.REFER_A_LEARNER_THANK_YOU}
                          element={
                            <CustomRoute
                              Component={Pages.ReferLearnerThankYou}
                              layout="side"
                              image="secondaryColor"
                            />
                          }
                        />
                        <Route
                          path={navRoutes.GENERAL.INTEREST}
                          element={
                            <CustomRoute
                              Component={Pages.InterestForm}
                              layout="center"
                            />
                          }
                        />
                        <Route
                          path={navRoutes.GENERAL.INTEREST_THANK_YOU}
                          element={
                            <CustomRoute
                              Component={Pages.InterestFormThankYou}
                              layout="center"
                              publicOnly
                            />
                          }
                        />
                        <Route
                          path={navRoutes.LEARNER.CHAT_WITH_COACH}
                          element={
                            <CustomRoute
                              Component={Pages.Learner.ChatWithCoach}
                              layout="center"
                              isPrivate
                              allowedRoles={[userRoles.LEARNER]}
                              contentProps={{
                                style: {
                                  display: 'flex',
                                  flexDirection: 'column',
                                },
                              }}
                              contentBodyProps={{
                                style: {
                                  display: 'flex',
                                  flexDirection: 'column',
                                  minHeight: 'unset',
                                },
                              }}
                              exercise
                            />
                          }
                        />
                        <Route
                          path={navRoutes.LEARNER.WELCOME_SELF_SIGNUP}
                          element={
                            <CustomRoute
                              Component={Pages.Learner.SelfSignupWelcome}
                              layout="center"
                              isPrivate
                              allowedRoles={[
                                userRoles.LEARNER,
                                userRoles.COACH,
                              ]}
                              contentProps={{
                                style: {
                                  display: 'flex',
                                  flexDirection: 'column',
                                },
                              }}
                              contentBodyProps={{
                                style: {
                                  flexGrow: 1,
                                  display: 'flex',
                                  flexDirection: 'column',
                                },
                              }}
                              exercise
                            />
                          }
                        />
                        <Route
                          path={navRoutes.LEARNER.MENU}
                          element={
                            <CustomRoute
                              Component={Pages.Learner.Menu}
                              layout="center"
                              contentBodyProps={{
                                paddingTop: '24px',
                                ptT: '24px',
                                plT: '0',
                                prT: '0',
                                ptM: '24px',
                                plM: '0',
                                prM: '0',
                              }}
                              exercise
                              isPrivate
                              allowedRoles={[userRoles.LEARNER]}
                            />
                          }
                        />

                        <Route
                          path={navRoutes.LEARNER.WELCOME}
                          element={
                            <CustomRoute
                              Component={Pages.Learner.Welcome}
                              layout="center"
                              isPrivate
                              allowedRoles={[
                                userRoles.LEARNER,
                                userRoles.COACH,
                              ]}
                              exercise
                            />
                          }
                        />

                        <Route
                          path={navRoutes.LEARNER.DASHBOARD}
                          element={
                            <CustomRoute
                              Component={Pages.LearnerDashboard}
                              layout="center"
                              exercise
                              contentBodyProps={{
                                paddingTop: '0',
                                ptT: '0',
                                plT: '0',
                                prT: '0',
                                ptM: '0',
                                plM: '0',
                                prM: '0',
                              }}
                              isPrivate
                              allowedRoles={[
                                userRoles.LEARNER,
                                userRoles.COACH,
                              ]}
                            />
                          }
                        />
                        <Route
                          path={navRoutes.COMMON.LEARNER_PAGE_PREVIEW}
                          element={
                            <CustomRoute
                              Component={Pages.LearnerDashboard}
                              layout="center"
                              contentBodyProps={{
                                paddingTop: '0',
                                ptT: '0',
                                plT: '0',
                                prT: '0',
                                ptM: '0',
                                plM: '0',
                                prM: '0',
                              }}
                              forPublic
                            />
                          }
                        />
                        <Route
                          path={navRoutes.COMMON.EXERCISES_PLAYGROUND}
                          element={
                            <CustomRoute
                              Component={Pages.LearnerDashboard}
                              layout="center"
                              contentBodyProps={{
                                paddingTop: '0',
                                ptT: '0',
                                plT: '0',
                                prT: '0',
                                ptM: '0',
                                plM: '0',
                                prM: '0',
                              }}
                              forPublic
                            />
                          }
                        />
                        <Route
                          path={navRoutes.COMMON.ACCOUNT_DETAILS}
                          element={
                            <CustomRoute
                              Component={Pages.AccountDetails}
                              layout="center"
                              isPrivate
                              allowedRoles={[
                                userRoles.ADMIN,
                                userRoles.FACILITATOR,
                                userRoles.COACH,
                                userRoles.ORGANISATION_ADMIN,
                              ]}
                            />
                          }
                        />
                        <Route
                          path={navRoutes.LEARNER.MY_COACHING_SESSIONS}
                          element={
                            <CustomRoute
                              Component={Pages.Learner.MyCoachingSessions}
                              layout="center"
                              exercise
                              contentProps={{
                                style: {
                                  display: 'flex',
                                  flexDirection: 'column',
                                },
                              }}
                              contentBodyProps={{
                                pt: '16px',
                                style: {
                                  flexGrow: 1,
                                  display: 'flex',
                                  flexDirection: 'column',
                                },
                              }}
                              isPrivate
                              allowedRoles={[userRoles.LEARNER]}
                            />
                          }
                        />
                        <Route
                          path={navRoutes.LEARNER.PROFILE}
                          element={
                            <CustomRoute
                              Component={Pages.Learner.Profile}
                              layout="center"
                              exercise
                              contentBodyProps={{
                                pt: '16px',
                              }}
                              isPrivate
                              allowedRoles={[
                                userRoles.LEARNER,
                                userRoles.COACH,
                              ]}
                            />
                          }
                        />
                        <Route
                          path={navRoutes.LEARNER.SUCCESS_MILESTONE}
                          element={
                            <CustomRoute
                              Component={Pages.Learner.MilestoneSuccess}
                              layout="general"
                              isPrivate
                              allowedRoles={[
                                userRoles.LEARNER,
                                userRoles.COACH,
                              ]}
                            />
                          }
                        />
                        <Route
                          path={navRoutes.COACH.REVIEW_LEARNER_SELF_LEARNING}
                          element={
                            <CustomRoute
                              Component={Pages.Coach.ReviewSelfLearning}
                              layout="side"
                              sideColor="neutralLight"
                              isPrivate
                              allowedRoles={[userRoles.COACH]}
                            />
                          }
                        />
                        <Route
                          path={navRoutes.COACH.NEW_LEARNER}
                          element={
                            <CustomRoute
                              Component={Pages.Coach.NewLearner}
                              layout="center"
                              isPrivate
                              allowedRoles={[userRoles.COACH]}
                            />
                          }
                        />
                        <Route
                          path={navRoutes.COACH.TRAINING_CHECKLIST}
                          element={
                            <CustomRoute
                              Component={Pages.Coach.TrainingChecklist}
                              layout="side"
                              sideColor="neutralLight"
                              isPrivate
                              allowedRoles={[userRoles.COACH]}
                            />
                          }
                        />
                        <Route
                          path={navRoutes.COACH.INTRODUCTORY_MEETING}
                          element={
                            <CustomRoute
                              Component={Pages.Coach.IntroMeeting}
                              layout="side"
                              sideColor="neutralLight"
                              isPrivate
                              allowedRoles={[userRoles.COACH]}
                            />
                          }
                        />

                        <Route
                          path={navRoutes.COACH.TRAINING}
                          element={
                            <Navigate
                              to={{
                                pathname: navRoutes.COACH.TRAINING_STEP.replace(
                                  ':stepId',
                                  '1'
                                ),
                              }}
                            />
                          }
                        />
                        <Route
                          path={navRoutes.COACH.TRAINING_STEP}
                          element={
                            <CustomRoute
                              Component={Pages.Coach.TrainingDetailPage}
                              isPrivate
                              layout="full"
                              overlayColor
                              allowedRoles={[userRoles.COACH]}
                              facilitatorView
                            />
                          }
                        />
                        <Route
                          path={navRoutes.COACH.TRAINING_REVIEW}
                          element={
                            <CustomRoute
                              Component={Pages.Coach.TrainingReview}
                              isPrivate
                              layout="center"
                              overlayColor
                              allowedRoles={[userRoles.COACH]}
                              facilitatorView
                            />
                          }
                        />
                        <Route
                          path={navRoutes.COACH.TRAINING_COMPLETE}
                          element={
                            <CustomRoute
                              Component={Pages.Coach.TrainingComplete}
                              isPrivate
                              layout="center"
                              overlayColor
                              allowedRoles={[userRoles.COACH]}
                              facilitatorView
                            />
                          }
                        />
                        <Route
                          path={navRoutes.LEARNER.ONBOARDING_1}
                          element={
                            <CustomRoute
                              Component={Pages.Learner.Onboarding1}
                              layout="side"
                              sideColor="neutralLight"
                              isPrivate
                              allowedRoles={[
                                userRoles.COACH,
                                userRoles.LEARNER,
                              ]}
                            />
                          }
                        />
                        <Route
                          path={navRoutes.LEARNER.ONBOARDING_4}
                          element={
                            <CustomRoute
                              Component={Pages.Learner.Onboarding4}
                              layout="side"
                              sideColor="neutralLight"
                              isPrivate
                              allowedRoles={[
                                userRoles.COACH,
                                userRoles.LEARNER,
                              ]}
                            />
                          }
                        />
                        <Route
                          path={navRoutes.LEARNER.ONBOARDING_2}
                          element={
                            <CustomRoute
                              Component={Pages.Learner.Onboarding2}
                              layout="side"
                              sideColor="neutralLight"
                              isPrivate
                              allowedRoles={[
                                userRoles.COACH,
                                userRoles.LEARNER,
                              ]}
                            />
                          }
                        />
                        <Route
                          path={navRoutes.LEARNER.PROGRESS_CHECK_QUESTIONS}
                          element={
                            <ProgressCheckProvider>
                              <CustomRoute
                                Component={Pages.Learner.ProgressCheckStep}
                                layout="general"
                                headerProps={{
                                  type: 'progress',
                                }}
                                image="secondaryColor"
                                allowedRoles={[
                                  userRoles.COACH,
                                  userRoles.LEARNER,
                                ]}
                              />
                            </ProgressCheckProvider>
                          }
                        />
                        <Route
                          path={navRoutes.LEARNER.PROGRESS_PROFILE_QUESTIONS}
                          element={
                            <CustomRoute
                              Component={Pages.Learner.ProgressProfileQuestions}
                              layout="general"
                              image="secondaryColor"
                            />
                          }
                        />
                        <Route
                          path={navRoutes.LEARNER.PROGRESS_REPORT_QUESTIONS}
                          element={
                            <CustomRoute
                              Component={Pages.Learner.Questions}
                              layout="side"
                              sideColor="neutralLight"
                              isPrivate
                              allowedRoles={[
                                userRoles.COACH,
                                userRoles.LEARNER,
                              ]}
                            />
                          }
                        />
                        <Route
                          path={navRoutes.LEARNER.SELECT_STARTING_POINT}
                          element={
                            <CustomRoute
                              Component={
                                Pages.Learner.InitialAssessment
                                  .SelectStartingPoint
                              }
                              layout="side"
                              sideColor="neutralLight"
                              isPrivate
                              allowedRoles={[
                                userRoles.COACH,
                                userRoles.LEARNER,
                              ]}
                            />
                          }
                        />
                        <Route
                          path={navRoutes.LEARNER.SELECT_MILESTONE}
                          element={
                            <CustomRoute
                              Component={
                                Pages.Learner.InitialAssessment.SelectMilestone
                              }
                              layout="side"
                              sideColor="neutralLight"
                              isPrivate
                              allowedRoles={[
                                userRoles.COACH,
                                userRoles.FACILITATOR,
                                userRoles.ORGANISATION_ADMIN,
                                userRoles.ADMIN,
                              ]}
                            />
                          }
                        />
                        <Route
                          path={navRoutes.LEARNER.PROGRESS_FIRST_PAGE}
                          element={
                            <CustomRoute
                              Component={Pages.Learner.FirstPage}
                              layout="side"
                              sideColor="neutralLight"
                              isPrivate
                              allowedRoles={[
                                userRoles.COACH,
                                userRoles.LEARNER,
                              ]}
                            />
                          }
                        />
                        <Route
                          path={
                            navRoutes.LEARNER.LEARNING_BY_YOURSELF_OR_WITH_COACH
                          }
                          element={
                            <CustomRoute
                              Component={
                                Pages.Learner.LearningByYourselfOrWithCoach
                              }
                              layout="side"
                              sideColor="neutralLight"
                              isPrivate
                              allowedRoles={[
                                userRoles.COACH,
                                userRoles.LEARNER,
                              ]}
                            />
                          }
                        />
                        <Route
                          path={navRoutes.LEARNER.LEARNER_ID}
                          element={
                            <CustomRoute
                              Component={Pages.Coach.LearnerId}
                              layout="side"
                              sideColor="neutralLight"
                              isPrivate
                              allowedRoles={[
                                userRoles.COACH,
                                userRoles.LEARNER,
                              ]}
                            />
                          }
                        />
                        <Route
                          path={navRoutes.LEARNER.SETUP_PROCESS}
                          element={
                            <CustomRoute
                              Component={Pages.Learner.SetupProcess}
                              layout="side"
                              sideColor="neutralLight"
                              isPrivate
                              allowedRoles={[
                                userRoles.COACH,
                                userRoles.LEARNER,
                              ]}
                            />
                          }
                        />
                        <Route
                          path={navRoutes.LEARNER.SOUND_BOARD}
                          element={
                            <CustomRoute
                              Component={Pages.Learner.SoundBoard}
                              layout="general"
                              isPrivate
                              allowedRoles={[userRoles.LEARNER]}
                            />
                          }
                        />
                        <Route
                          path={navRoutes.LEARNER.ICONS}
                          element={
                            <CustomRoute
                              Component={Pages.Learner.IconsPage}
                              layout="side"
                              isPrivate
                              allowedRoles={[userRoles.LEARNER]}
                            />
                          }
                        />
                        {[
                          navRoutes.LEARNER.COLOURED_OVERLAY,
                          navRoutes.COACH.COLOURED_OVERLAY,
                        ].map((path) => (
                          <Route
                            key={path}
                            path={path}
                            element={
                              <CustomRoute
                                Component={Pages.OverlayColor}
                                layout="general"
                                image="secondaryColor"
                                isPrivate
                                allowedRoles={[
                                  userRoles.LEARNER,
                                  userRoles.COACH,
                                ]}
                              />
                            }
                          />
                        ))}
                        <Route
                          path={navRoutes.LEARNER.GOALS}
                          element={
                            <CustomRoute
                              Component={Pages.Learner.Goals}
                              layout="center"
                              contentBodyProps={{
                                paddingTop: '24px',
                                ptT: '24px',
                                plT: '0',
                                prT: '0',
                                ptM: '24px',
                                plM: '0',
                                prM: '0',
                              }}
                              isPrivate
                              allowedRoles={[userRoles.LEARNER]}
                              exercise
                            />
                          }
                        />
                        <Route
                          path={navRoutes.COACH.LEARNER_DASHBOARD}
                          element={
                            <CustomRoute
                              Component={Pages.LearnerDashboard}
                              layout="center"
                              contentBodyProps={{
                                paddingTop: '0',
                                ptT: '0',
                                plT: '0',
                                prT: '0',
                                ptM: '0',
                                plM: '0',
                                prM: '0',
                              }}
                              isPrivate
                              allowedRoles={[userRoles.COACH]}
                              image="secondaryColor"
                              headerProps={{
                                learnerDashboard: true,
                              }}
                            />
                          }
                        />
                        <Route
                          path={navRoutes.COACH.PREPARE_MILESTONE}
                          element={
                            <CustomRoute
                              Component={Pages.Coach.PrepareMilestone}
                              layout="full"
                              isPrivate
                              allowedRoles={[userRoles.COACH]}
                            />
                          }
                        />

                        <Route
                          path={navRoutes.COACH.LEARNER_DASHBOARD}
                          element={
                            <CustomRoute
                              Component={Pages.LearnerDashboard}
                              layout="center"
                              contentBodyProps={{
                                paddingTop: '0',
                                ptT: '0',
                                plT: '0',
                                prT: '0',
                                ptM: '0',
                                plM: '0',
                                prM: '0',
                              }}
                              isPrivate
                              allowedRoles={[userRoles.COACH]}
                              image="secondaryColor"
                              headerProps={{
                                learnerDashboard: true,
                              }}
                            />
                          }
                        />
                        <Route
                          path={navRoutes.LEARNER.COACH_CODE}
                          element={
                            <CustomRoute
                              Component={Pages.Learner.CoachCode}
                              layout="general"
                              image="secondaryColor"
                              allowedRoles={[userRoles.LEARNER]}
                            />
                          }
                        />
                        <Route
                          path={navRoutes.LEARNER.PROGRESS_CHECK_THANK_YOU}
                          element={
                            <CustomRoute
                              Component={Pages.Learner.ProgressCheckThankYou}
                              layout="general"
                              image="secondaryColor"
                              allowedRoles={[userRoles.LEARNER]}
                            />
                          }
                        />

                        <Route
                          path={navRoutes.COACH.LEARNERS}
                          element={
                            <CustomRoute
                              Component={Pages.Coach.Learners}
                              layout="center"
                              isPrivate
                              allowedRoles={[userRoles.COACH]}
                            />
                          }
                        />
                        <Route
                          path={navRoutes.COACH.LEARNERS_STUCK_AT}
                          element={
                            <CustomRoute
                              Component={Pages.Coach.LearnersStuckAt}
                              layout="center"
                              isPrivate
                              allowedRoles={[userRoles.COACH]}
                            />
                          }
                        />
                        <Route
                          path={navRoutes.COACH.REVIEW_STUCK_AT}
                          element={
                            <CustomRoute
                              Component={Pages.StuckAtReview}
                              isPrivate
                              allowedRoles={[userRoles.COACH]}
                            />
                          }
                        />

                        <Route
                          path={navRoutes.COACH.PROFILE}
                          element={
                            <CustomRoute
                              Component={Pages.Coach.Profile}
                              layout="center"
                              isPrivate
                              allowedRoles={[userRoles.COACH]}
                            />
                          }
                        />
                        <Route
                          path={navRoutes.COACH.CREATE_COACHING_SESSION}
                          element={
                            <CustomRoute
                              Component={
                                Pages.Coach.DashboardSubPages
                                  .ArrangeCoachingSession
                              }
                              layout="center"
                              sideColor="neutralLight"
                              isPrivate
                              allowedRoles={[userRoles.COACH]}
                            />
                          }
                        />
                        <Route
                          path={navRoutes.COACH.CHAT_SAFEGUARD}
                          element={
                            <CustomRoute
                              Component={Pages.Coach.ChatSafeGuard}
                              layout="center"
                              sideColor="neutralLight"
                              isPrivate
                              allowedRoles={[userRoles.COACH]}
                            />
                          }
                        />
                        <Route
                          path={navRoutes.COACH.EDIT_COACHING_SESSION}
                          element={
                            <CustomRoute
                              Component={
                                Pages.Coach.DashboardSubPages
                                  .ArrangeCoachingSession
                              }
                              layout="center"
                              sideColor="neutralLight"
                              isPrivate
                              allowedRoles={[userRoles.COACH]}
                            />
                          }
                        />
                        <Route
                          path={navRoutes.COACH.ALL_SESSIONS}
                          element={
                            <CustomRoute
                              Component={
                                Pages.Coach.DashboardSubPages.AllSessions
                              }
                              layout="center"
                              sideColor="neutralLight"
                              isPrivate
                              allowedRoles={[userRoles.COACH]}
                              contentBodyProps={{ maxWidth: '1038px' }}
                            />
                          }
                        />
                        <Route
                          path={navRoutes.COACH.DASHBOARD_ADVICE}
                          element={
                            <CustomRoute
                              Component={Pages.Coach.DashboardSubPages.Advice}
                              layout="side"
                              sideColor="neutralLight"
                              isPrivate
                              allowedRoles={[userRoles.COACH]}
                            />
                          }
                        />
                        <Route
                          path={navRoutes.COACH.DASHBOARD_TRAINING_CHECKLIST}
                          element={
                            <CustomRoute
                              Component={
                                Pages.Coach.DashboardSubPages.TrainingChecklist
                              }
                              layout="side"
                              sideColor="neutralLight"
                              isPrivate
                              allowedRoles={[userRoles.COACH]}
                            />
                          }
                        />
                        <Route
                          path={navRoutes.COACH.DASHBOARD_SAFEGUARDING}
                          element={
                            <CustomRoute
                              Component={
                                Pages.Coach.DashboardSubPages.Safeguarding
                              }
                              layout="side"
                              sideColor="neutralLight"
                              isPrivate
                              allowedRoles={[userRoles.COACH]}
                            />
                          }
                        />
                        <Route
                          path={navRoutes.COACH.DASHBOARD_QUALITIES}
                          element={
                            <CustomRoute
                              Component={
                                Pages.Coach.DashboardSubPages.Qualities
                              }
                              layout="side"
                              sideColor="neutralLight"
                              isPrivate
                              allowedRoles={[userRoles.COACH]}
                            />
                          }
                        />
                        <Route
                          path={navRoutes.COACH.DASHBOARD}
                          element={
                            <CustomRoute
                              Component={Pages.Coach.Dashboard}
                              layout="full"
                              isPrivate
                              allowedRoles={[userRoles.COACH]}
                            />
                          }
                        />
                        <Route
                          path={navRoutes.LEARNER.ONBOARDING_5}
                          element={
                            <CustomRoute
                              Component={Pages.Learner.Onboarding5}
                              layout="side"
                              sideColor="neutralLight"
                              isPrivate
                              allowedRoles={[
                                userRoles.COACH,
                                userRoles.LEARNER,
                              ]}
                            />
                          }
                        />
                        <Route
                          path={navRoutes.LEARNER.PROGRESS_REPORT_COMPLETED}
                          element={
                            <CustomRoute
                              Component={Pages.Learner.ReportCompleted}
                              layout="side"
                              sideColor="neutralLight"
                              isPrivate
                              allowedRoles={[
                                userRoles.COACH,
                                userRoles.LEARNER,
                              ]}
                            />
                          }
                        />
                        <Route
                          path={navRoutes.COACH.ONBOARDING_1}
                          element={
                            <CustomRoute
                              Component={Pages.Coach.Onboarding1}
                              layout="side"
                              sideColor="neutralLight"
                              isPrivate
                              allowedRoles={[userRoles.COACH]}
                            />
                          }
                        />
                        <Route
                          path={navRoutes.COACH.ONBOARDING_2}
                          element={
                            <CustomRoute
                              Component={Pages.Coach.Onboarding2}
                              layout="side"
                              sideColor="neutralLight"
                              isPrivate
                              allowedRoles={[userRoles.COACH]}
                            />
                          }
                        />
                        <Route
                          path={navRoutes.COACH.ONBOARDING_3}
                          element={
                            <CustomRoute
                              Component={Pages.Coach.Onboarding3}
                              layout="side"
                              sideColor="neutralLight"
                              isPrivate
                              allowedRoles={[userRoles.COACH]}
                            />
                          }
                        />
                        <Route
                          path={navRoutes.COACH.ONBOARDING_4}
                          element={
                            <CustomRoute
                              Component={Pages.Coach.Onboarding4}
                              layout="side"
                              sideColor="neutralLight"
                              isPrivate
                              allowedRoles={[userRoles.COACH]}
                            />
                          }
                        />
                        <Route
                          path={navRoutes.COACH.ONBOARDING_5}
                          element={
                            <CustomRoute
                              Component={Pages.Coach.Onboarding5}
                              layout="side"
                              sideColor="neutralLight"
                              isPrivate
                              allowedRoles={[userRoles.COACH]}
                            />
                          }
                        />
                        <Route
                          path={navRoutes.COACH.ONBOARDING_6}
                          element={
                            <CustomRoute
                              Component={Pages.Coach.Onboarding6}
                              layout="side"
                              sideColor="neutralLight"
                              isPrivate
                              allowedRoles={[userRoles.COACH]}
                            />
                          }
                        />
                        <Route
                          path={navRoutes.COACH.CHAT_WITH_LEARNER}
                          element={
                            <CustomRoute
                              Component={Pages.Coach.ChatWithLearner}
                              layout="center"
                              isPrivate
                              allowedRoles={[userRoles.COACH]}
                              contentProps={{
                                style: {
                                  display: 'flex',
                                  flexDirection: 'column',
                                },
                              }}
                              contentBodyProps={{
                                style: {
                                  flexGrow: 1,
                                  display: 'flex',
                                  flexDirection: 'column',
                                },
                              }}
                            />
                          }
                        />
                        <Route
                          path={`${navRoutes.FACILITATOR.BASE}/*`}
                          element={<Pages.Facilitator />}
                        />
                        <Route
                          path={`${navRoutes.EXERCISES.BASE}/*`}
                          element={<Pages.Exercises />}
                        />
                        <Route
                          path={`${navRoutes.CMS.BASE}/*`}
                          element={<Pages.CMS />}
                        />
                        <Route
                          path={`${navRoutes.ADMIN.BASE}/*`}
                          element={<AdminRouter />}
                        />
                        <Route
                          path={`${navRoutes.ORGANISATION_ADMIN.BASE}/*`}
                          element={<OrganisationAdminRouter />}
                        />

                        <Route path={`*`} element={<CommonRouter />} />
                      </Route>
                    </Routes>
                  </BrowserRouter>
                </SpeechSynthesisProvider>
              </GeneralStateProvider>
            </AuthProvider>
          </SiteSettingsProvider>
        </MuiThemeProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
