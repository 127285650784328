import React, { useState, useRef } from 'react';
import theme from 'theme';
import { Icon } from 'components';
import { Col, Row } from '../../../../components/Grid';
import { ClearButton, PlayButton } from '../../../../components/Button';
import StepFooterButtons from '../StepFooterButtons';
import * as T from '../../../../components/Typography';
import * as S from './style';

const Trace = ({
  data: { correctOptions, textAudio, textAudioUrl } = {},
  imageUrl,
  handleNext,
  disabled,
  stuckAtReview,
  preview,
}) => {
  const [enableNext, setEnableNext] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const canvasRef = useRef(null);
  const accordionTitle = isExpanded ? 'Hide answers' : 'Show answers';

  return (
    <S.Wrapper>
      <Row jc="center" mb={6}>
        {textAudio && (
          <Col w={[4, 7, 7]} mt={6}>
            {textAudioUrl ? (
              <PlayButton
                audioUrl={textAudioUrl}
                iconProps={{ width: '32px', height: '32px' }}
                padding="12px"
                weight="regular"
                fallbackText={textAudio}
              >
                {textAudio}
              </PlayButton>
            ) : (
              <T.P size="med">{textAudio}</T.P>
            )}
          </Col>
        )}
      </Row>
      <Row jc="center" mb={6}>
        <Col w={[4, 7, 7]} mt={6}>
          <S.StyledCanvasDraw
            brushColor={`${theme.colors.neutralMain}`}
            imageUrl={imageUrl}
            gridColor={theme.colors.neutral80}
            hideGrid={false}
            hideInterface={true}
            canvasWidth={800}
            canvasHeight={400}
            gridSizeX={15}
            gridSizeY={15}
            gridLineWidth={0.5}
            hideGridX={false}
            hideGridY={false}
            ref={canvasRef}
            onChange={() => {
              setEnableNext(true);
            }}
            lazyRadius={0}
          />
        </Col>
        <Col w={[4, 7, 7]} mt={6}>
          <ClearButton
            handleClick={() => {
              canvasRef.current.clear();
              setEnableNext(false);
            }}
          />
        </Col>
      </Row>
      {correctOptions?.length > 0 && (
        <Row jc="center" mb={6}>
          <Col w={[4, 7, 7]} mt={6}>
            {/* TODO: explore making this a reusable component to use in CompletedCard */}
            <S.AccordionContainer>
              <S.AccordionTitle
                onClick={() => setIsExpanded((prevState) => !prevState)}
              >
                <Row ai="center">
                  <PlayButton
                    width="32px"
                    height="32px"
                    audioUrl=""
                    iconProps={{ width: 27.2 }}
                    backgroundColor="transparent"
                    withoutButtonStyle
                    fallbackText={accordionTitle}
                  />
                  <T.P weight="bold" textDecoration="underline">
                    {accordionTitle}
                  </T.P>
                </Row>

                <Icon
                  icon={isExpanded ? 'arrowDropUp' : 'arrowDropDown'}
                  aria-label={accordionTitle}
                />
              </S.AccordionTitle>

              <S.AccordionItem collapsed={!isExpanded}>
                <Row jc="center" mb={6}></Row>
                {correctOptions?.map((option) => (
                  <Col key={option.id} w={[4, 12, 12]} mt={6}>
                    <S.AccordionImage
                      src={option.imageUrl}
                      alt={option.title}
                    />
                  </Col>
                ))}
              </S.AccordionItem>
            </S.AccordionContainer>
          </Col>
        </Row>
      )}
      <Row jc="center" mb={6}>
        <Col w={[4, 7, 7]} mt={6}>
          <StepFooterButtons
            stuckAtReview={stuckAtReview}
            handleNext={handleNext}
            preview={preview}
            disabled={disabled}
            enableNext={enableNext}
          />
        </Col>
      </Row>
    </S.Wrapper>
  );
};

export default Trace;
