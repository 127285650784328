export const encodeURL = (url) => {
  try {
    const [baseUrl, query] = url.split('?');

    const encodedQuery = query
      .split('&')
      .map((param) => {
        const [key, value] = param.split('=');
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      })
      .join('&');

    return `${baseUrl}?${encodedQuery}`;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Invalid URL:', error.message);
    return null;
  }
};
