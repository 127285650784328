import { useEffect } from 'react';

/**
 * sets the window inner height as a CSS variable
 * to calculate an accurate height on mobile browsers
 */
const useViewportHeight = () => {
  useEffect(() => {
    const setVh = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    // Set initial value and update on resize
    setVh();
    window.addEventListener('resize', setVh);

    // Cleanup on component unmount
    return () => window.removeEventListener('resize', setVh);
  }, []);
};

export default useViewportHeight;
