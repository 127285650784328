import React from 'react';
import * as S from './style';
import { IconButton } from 'components/Button';
import Icon from 'components/Icon';

const ModalNew = ({
  visible,
  setIsModalVisible,
  children,
  onCancel,
  bgColor,
  maskColor,
  maxWidth,
  showCloseIcon,
  padding,
  ...props
}) => {
  const handleCancel = () => {
    if (typeof onCancel === 'function') {
      onCancel();
    }
    if (typeof setIsModalVisible === 'function') {
      setIsModalVisible(false);
    }
  };

  return (
    <S.Modal
      open={visible}
      onClose={handleCancel}
      aria-label="dialog"
      aria-modal="true"
      role="dialog"
      BackdropProps={{
        style: { backgroundColor: maskColor || 'rgba(5, 13, 48, 0.7)' },
      }}
      disableEnforceFocus
      maxWidth={maxWidth}
      {...props}
    >
      <S.Container bgColor={bgColor} padding={padding}>
        {showCloseIcon && (
          <IconButton
            style={{
              position: 'absolute',
              zIndex: 100,
              top: '8px',
              right: '8px',
            }}
            onClick={handleCancel}
          >
            <Icon icon="cross" color="white" />
          </IconButton>
        )}
        {children}
      </S.Container>
    </S.Modal>
  );
};

export default ModalNew;
