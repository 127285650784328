import styled from '@emotion/styled';

export const Wrapper = styled.article`
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  background: ${({ theme, bgColor }) =>
    bgColor ? theme.colors[bgColor] || bgColor : 'transparent'};
`;

export const Content = styled.main`
  width: 100%;
  flex-grow: 1;
  /* overflow: hidden; */
  overflow-y: auto;
  max-width: ${({ maxWidth }) => maxWidth || '920px'};
  margin-inline: auto;
  background-color: ${({ theme, bgColor }) =>
    bgColor && bgColor !== 'default'
      ? theme.colors[bgColor]
      : theme.colors.white};
`;

export const ContentBody = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-inline: auto;
  max-width: ${({ maxWidth }) => maxWidth || '920px'};
  background-color: ${({ theme, bgColor }) => theme.colors.white};

  padding-top: ${({ paddingTop }) => paddingTop || '58px'};
  padding-bottom: 30px;

  ${({ theme }) => theme.media.tablet} {
    align-items: stretch;
    min-height: ${({ theme, headerType }) =>
      `calc(100vh - ${
        headerType === 'progress'
          ? theme.constants.layout.header.progressHeight
          : theme.constants.layout.header.height
      })`};

    padding-top: ${({ theme: { constants }, ptT }) =>
      ptT || constants.layout.side.top.tablet};

    padding-left: ${({ theme: { constants }, plT }) =>
      plT || constants.layout.side.leftPadding.tablet};
    padding-right: ${({ theme: { constants }, prT }) =>
      prT || constants.layout.side.leftPadding.tablet};

    padding-bottom: ${({ theme: { spacings } }) => spacings[7]};
  }
  ${({ theme }) => theme.media.mobile} {
    padding-bottom: ${({ theme: { spacings } }) => spacings[6]};
    padding-top: ${({ theme: { constants }, ptM }) =>
      ptM || constants.layout.side.top.mobile};

    padding-left: ${({ theme: { constants }, plM }) =>
      plM || constants.layout.side.leftPadding.mobile};
    padding-right: ${({ theme: { constants }, prM }) =>
      prM || constants.layout.side.leftPadding.mobile};
  }
`;
