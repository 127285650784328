import React from 'react';
import PropTypes from 'prop-types';

import General from './General';
import Side from './Side';
import Full from './Full';
import FullScreenEx from './FullScreenEx';
import FullScreenComprehension from './FullScreenComprehension';
import NewBack from './NewBack';
import Centered from './Centered';
import CMSLayout from './CMS';
import useViewportHeight from 'Hooks/useViewportHeight';

export const layoutTypes = [
  'CMS',
  'side',
  'full',
  'center',
  'fullScreenEx',
  'fullScreenComprehension',
  'general',
];

const Layout = ({ layout, ...props }) => {
  useViewportHeight();

  switch (layout) {
    case 'CMS':
      return <CMSLayout {...props} />;
    case 'side':
      return <Side {...props} />;
    case 'full':
      return <Full {...props} />;
    case 'center':
      return <Centered {...props} />;
    case 'fullScreenEx':
      return <FullScreenEx {...props} />;
    case 'fullScreenComprehension':
      return <FullScreenComprehension {...props} />;
    case 'newBack':
      return <NewBack {...props} />;
    case 'general':
    default:
      return <General {...props} />;
  }
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  layout: PropTypes.oneOf(...layoutTypes),
};

export { General, Side, Full, FullScreenEx, FullScreenComprehension };
export default Layout;
