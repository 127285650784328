import React from 'react';
import * as S from './style';
import Graphics from '../../../components/Graphics';
import Header from '../Header';
import Footer from '../Footer';
import { useMediaQuery } from 'react-responsive';
import theme from '../../../theme';
import { useAuth } from './../../../context/auth';

const General = ({
  children,
  image,
  sideColor = 'neutralLight',
  headerProps = {},
  fullWidth,
  contentBodyProps,
  ...props
}) => {
  const {
    user: { overlayColor },
  } = useAuth();

  const isTablet = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.tablet})`,
  });
  return (
    <S.Wrapper {...props} bgColor={overlayColor}>
      {!isTablet && <Graphics image={image} bgColor={sideColor} general />}
      <S.Content>
        <Header exercise {...headerProps} />
        <S.ContentBody
          removePaddingTop
          fullWidth={fullWidth}
          headerType={headerProps.type}
          {...contentBodyProps}
        >
          {children}
        </S.ContentBody>
        <Footer />
      </S.Content>
      {!isTablet && <Graphics image={image} bgColor={sideColor} general />}
    </S.Wrapper>
  );
};

export default General;
