import React, { useState } from 'react';
import { Dropdown } from 'components/Inputs';
import Loading from 'components/Loading';
import { useAuth } from '../../../context/auth';
import { Col, Row } from '../../../components/Grid';
import * as S from './style';
import * as T from '../../../components/Typography';
import { BasicButton } from 'components/Button';
import { useGeneralState } from 'context/general-state';
import { navRoutes, userRoles } from '../../../constants';
import { useLocation, useNavigate } from 'react-router-dom';

const OrganisationsSelectWrapper = () => {
  const { user, setUser, resetStateAfterSwitchAccount } = useAuth();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { targetUrl, targetRole } = location.state || {};

  const { state: generalState, setState } = useGeneralState();

  const [
    selectedOrganisationAndRolesValue,
    setSelectedOrganisationAndRoleValues,
  ] = useState({
    organisationId: '',
    role: '',
    id: '',
  });

  const handleSubmit = async () => {
    setLoading(true);
    const { role, organisationId, id } = selectedOrganisationAndRolesValue;
    handleLoginSelectedUser({ role, organisationId, id });
  };

  const handleLoginSelectedUser = async ({ role, organisationId, id }) => {
    await resetStateAfterSwitchAccount({
      organisationId,
      role,
      id,
    });

    setUser({
      ...user,
      organisationId,
      role,
      id,
    });

    setState({ ...generalState, switchAccount: false });
    setLoading(false);

    sessionStorage.removeItem('SEEN_SPLASH');

    if (targetUrl) {
      navigate(targetUrl);
    } else if (role === userRoles.ORGANISATION_ADMIN) {
      navigate(navRoutes.ORGANISATION_ADMIN.DASHBOARD);
    } else {
      navigate(navRoutes.COMMON.SPLASH_SCREEN, {
        state: {
          role,
        },
      });
    }
  };

  if (!user || !user.activeOrganisationsWithActivatedStatus) return <Loading />;
  const { activeOrganisationsWithActivatedStatus } = user;

  // if the user has only one role in this course keep loading till the user is logged in
  if (activeOrganisationsWithActivatedStatus?.length === 1) {
    const { role, organisationId, id } =
      user.activeOrganisationsWithActivatedStatus[0];

    handleLoginSelectedUser({ role, organisationId, id });

    return <Loading />;
  }

  // if the user was redirected with targetRole,
  // show the select account(ex. view learner profile in another course)
  if (targetRole) {
    const selectedRole = user.activeOrganisationsWithActivatedStatus.find(
      (r) => r.role === targetRole
    );

    if (selectedRole) {
      const { role, organisationId, id } = selectedRole;

      handleLoginSelectedUser({ role, organisationId, id });

      return <Loading />;
    }
    // else show the select account page
  }

  // { [role]: {organisations: [organisationItem,..], label: roleItem} }
  const rolesLabel = {};
  activeOrganisationsWithActivatedStatus.forEach((activeRole) => {
    const { organisationName, organisationId, role, id } = activeRole;

    if (!rolesLabel[role]) {
      rolesLabel[role] = {
        organisations: [],
        label: {
          label: role.replace('_', ' '),
          bold: true,
          disabled: true,
        },
      };
    }

    rolesLabel[role].organisations.push({
      label: organisationName,
      bold: false,
      disabled: false,
      value: {
        organisationId: organisationId,
        role: role,
        id: id,
      },
    });
  });

  const optionsWithRoleAsLabel = Object.values(rolesLabel).reduce(
    (acc, curr) => {
      acc.push(curr.label);
      curr.organisations.forEach((organisation) => {
        acc.push(organisation);
      });

      return acc;
    },
    []
  );

  return (
    <S.Wrapper>
      <Row ai="center" mb={5}>
        <Col w={[4, 12, 12]}>
          <S.TableTitleWrapper>
            <T.H2 color="neutralMain" size="medLarge">
              Select account
            </T.H2>
          </S.TableTitleWrapper>
        </Col>
      </Row>
      <Col w={[4, 12, 12]}>
        <T.P color="neutralMain" weight="bold" mb="20px">
          Please select which account you wish to access
        </T.P>
        <Dropdown
          selected={activeOrganisationsWithActivatedStatus
            ?.map((x) => ({
              label: x.organisationName,
              value: {
                organisationId: x.organisationId,
                role: x.role,
                id: x.id,
              },
            }))
            ?.find(
              (x) =>
                x.value?.organisationId ===
                  selectedOrganisationAndRolesValue?.organisationId &&
                x.value?.role === selectedOrganisationAndRolesValue?.role &&
                x.value?.id === selectedOrganisationAndRolesValue?.id
            )}
          handleChange={(value) => setSelectedOrganisationAndRoleValues(value)}
          options={optionsWithRoleAsLabel}
        />
        <Row>
          <Col w={[4, 12, 12]}>
            <BasicButton
              mt={6}
              variant="primary"
              type="submit"
              handleClick={handleSubmit}
              loading={loading}
            >
              <T.P color="white" weight="semi">
                Confirm
              </T.P>
            </BasicButton>
          </Col>
        </Row>
      </Col>
    </S.Wrapper>
  );
};
export default OrganisationsSelectWrapper;
