import styled from '@emotion/styled';
import { atcb_action } from 'add-to-calendar-button-react';
import { useGetLearners } from 'api-calls/learners.queries';
import { BackButton } from 'components/BackButton';
import { BasicButton } from 'components/Button';
import { Col, Row } from 'components/Grid';
import {
  BasicInput,
  Dropdown,
  DatePicker,
  Radio,
  Checkbox,
} from 'components/Inputs';
import TimePicker from 'components/TimePicker';
import * as T from '../../../../components/Typography';
import { setMargin } from 'helpers';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import moment from 'moment';
// import { validateSession } from './validateme';
import {
  useCancelLearnerCoachSession,
  useCreateLearnerCoachSessions,
  useGetLearnerCoachSessionBySessionId,
  useUpdateLearnerCoachSession,
} from 'api-calls/learner-coach-sessions.queries';
import { navRoutes } from '../../../../constants';
import { createCoachSession as validate } from '../../../../validation/schemas';
import * as S from '../style';
import { ConfirmationSuccessModal, Loading } from 'components';
import { encodeURL } from 'utils/encodeUrl';

const ConfirmAlertWrapper = styled('div')`
  ${setMargin}
  border-radius: 1rem;
  padding: 1rem 1.5rem;
  background: #f5f5f5;
`;

const useForm = (initialValues) => {
  const [form, setFormData] = useState(initialValues);

  return {
    form,
    setFormData, // Expose setFormData for external updates
    handleChange: ({ name, value }) => {
      setFormData((prev) => {
        const updatedForm = { ...prev, [name]: value };

        if (name === 'type') {
          if (value === 'in-person') {
            updatedForm.link = '';
          } else {
            updatedForm.addressLine1 = '';
            updatedForm.addressLine2 = '';
            updatedForm.town = '';
            updatedForm.postcode = '';
          }
        }

        return updatedForm;
      });
    },
  };
};

const ArrangeCoachingSession = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const learnerIdFromQuery = searchParams.get('learnerUserId');
  const { learners = [], isLoading: isLearnersLoading } = useGetLearners();
  const [isDirty, setIsDirty] = useState(false);
  const [deleteModalType, setDeleteModalType] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  useEffect(() => {
    if (learnerIdFromQuery && !isLearnersLoading) {
      const currentLearner = learners.find(
        (learner) => learner.id === +learnerIdFromQuery
      );

      if (currentLearner) {
        setFormData((prev) => ({
          ...prev,
          learner: currentLearner.learnerUniqueId,
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [learnerIdFromQuery, learners, isLearnersLoading]);

  const { sessionIdP } = useParams();

  const { data: sessionDataToUpdate } = useGetLearnerCoachSessionBySessionId(
    {
      sessionId: sessionIdP,
    },
    { enabled: !!sessionIdP }
  );

  const isEditMode = !!sessionDataToUpdate;
  const isCancelled = sessionDataToUpdate?.status === 'CANCELLED';

  const { form, handleChange, setFormData } = useForm({
    learner: learnerIdFromQuery ?? '',
    date: '',
    timeFrom: '',
    timeTo: '',
    type: 'online',
    link: '',
    addressLine1: '',
    addressLine2: '',
    town: '',
    postcode: '',
    additionalInfo: '',
  });
  const inputRefs = useRef({});
  const createSessionMutation = useCreateLearnerCoachSessions();

  const updateSessionMutation = useUpdateLearnerCoachSession({
    sessionId: sessionIdP,
  });
  const {
    mutateAsync: cancelSession,
    isLoading: isCancelling,
    error: cancelError,
  } = useCancelLearnerCoachSession({
    sessionId: sessionIdP,
  });

  const {
    mutateAsync,
    isLoading,
    error: httpError,
  } = isEditMode ? updateSessionMutation : createSessionMutation;
  const [errors, setErrors] = useState({});

  const selectLearnerOptions = useMemo(() => {
    return learners.map((learner) => ({
      label: learner.firstName,
      value: learner.learnerUniqueId,
    }));
  }, [learners]);

  const validateForm = (data) => {
    try {
      if (!isDirty) {
        setIsDirty(true);
      }
      validate(data);
      setErrors({});
      return null;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setErrors(error.inner);
      }
      return error.inner;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const errorsObj = validateForm(form);

      if (errorsObj) {
        const firstErrorField = Object.keys(errorsObj)[0];
        const firstErrorRef = inputRefs.current[firstErrorField];

        // Scroll to the field if it exists
        if (firstErrorRef && firstErrorRef.scrollIntoView) {
          firstErrorRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
          // Optionally focus the input
          if (typeof firstErrorRef.focus === 'function') {
            firstErrorRef.focus();
          }
        }

        return;
      }

      await mutateAsync({
        learnerId: learners.find(
          (learner) => learner.learnerUniqueId === form.learner
        ).id,
        type: form.type.toUpperCase(),
        link: form.link,
        addressLine1: form.addressLine1,
        addressLine2: form.addressLine2,
        town: form.town,
        postcode: form.postcode,
        additionalInfo: form.additionalInfo,
        start: new Date(`${form.date}T${form.timeFrom}`).toISOString(),
        end: new Date(`${form.date}T${form.timeTo}`).toISOString(),
      });

      setShowSuccessModal(true);
    } catch (error) {
      const err = error.response?.data?.data || error;
      // handle validation from backend
      if (err.name === 'ValidationError') {
        let formattedErrors = err.inner;
        if (Array.isArray(err.inner)) {
          formattedErrors = err.inner.reduce((acc, error) => {
            return {
              ...acc,
              [error.path]: error.message,
            };
          }, {});
        }
        setErrors(formattedErrors);
      }
    }
  };

  const handleCancel = async (e) => {
    await cancelSession(
      {
        status: 'CANCELLED',
      },
      {
        onSuccess: () => {
          setDeleteModalType('cancelled');
        },
      }
    );
  };

  useEffect(() => {
    if (sessionDataToUpdate) {
      const timeFrom = sessionDataToUpdate.start
        ? moment(sessionDataToUpdate.start).format('HH:mm')
        : '';
      const timeTo = sessionDataToUpdate.end
        ? moment(sessionDataToUpdate.end).format('HH:mm')
        : '';
      const date = sessionDataToUpdate.start
        ? moment(sessionDataToUpdate.start).format('YYYY-MM-DD')
        : '';

      let {
        type,
        link,
        learnerUniqueId,
        addressLine1,
        addressLine2,
        town,
        postcode,
        additionalInfo,
      } = sessionDataToUpdate;

      type = type.toLowerCase();

      setFormData({
        timeFrom,
        timeTo,
        date,
        type,
        link,
        learner: learnerUniqueId,
        addressLine1,
        addressLine2,
        town,
        postcode,
        additionalInfo,
      });
    }
  }, [sessionDataToUpdate, setFormData]);

  useEffect(() => {
    if (isDirty) {
      validateForm(form);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, isDirty]);

  if (isLearnersLoading) {
    return <Loading />;
  }

  if (!learners.length) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '32px',
          width: '100%',
          maxWidth: '700px',
          margin: '0 auto',
        }}
      >
        <Row mb={6}>
          <BackButton />
        </Row>
        <Row>
          <Col w={[4, 12, 12]}>
            <T.H1 color="neutralMain" weight="bold" mt={1}>
              {isEditMode ? 'Edit' : 'Arrange'} Coaching Session
            </T.H1>
          </Col>
        </Row>
        <Row>
          <Col w={[4, 12, 12]} jc="center">
            <T.P>
              No learners found. You need to be assigned to one or more learners
              to arrange a coaching session.
            </T.P>
          </Col>
        </Row>
      </div>
    );
  }

  const submitDisabled = !form.confirmed;
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '32px',
        width: '100%',
        maxWidth: '700px',
        margin: '0 auto',
      }}
    >
      <Row mb={6}>
        <BackButton />
      </Row>
      <form onSubmit={handleSubmit}>
        <Row mb={6}>
          <Col w={[4, 12, 12]} jc="space-between">
            <T.H1 color="neutralMain" weight="bold" mt={1}>
              {isEditMode ? 'Edit' : 'Arrange'} Coaching Session
            </T.H1>
          </Col>
        </Row>
        <Row mb={6}>
          <Col w={[12, 12, 12]}>
            <Dropdown
              ref={(el) => (inputRefs.current['learner'] = el)}
              label="Select Learner"
              options={selectLearnerOptions}
              disabled={isEditMode}
              autocompleteProps={{
                getOptionLabel: (option) => {
                  return (
                    selectLearnerOptions.find(
                      (learner) => learner.value === option
                    )?.label ?? ''
                  );
                },
              }}
              selected={form.learner}
              handleChange={(value) => {
                handleChange({ name: 'learner', value });
              }}
              error={errors.learner}
            />
          </Col>
        </Row>
        <Row mb={6}>
          <Col w={[12, 12, 12]}>
            <DatePicker
              ref={(el) => (inputRefs.current['date'] = el)}
              label="Session Date"
              placeholder="DD/MM/YYYY"
              name="date"
              validDateLimit={null}
              minDateLimit={moment().format('YYYY-MM-DD')}
              value={form.date}
              handleChange={(value) => {
                handleChange({ name: 'date', value });
              }}
              error={errors.date}
              disabled={isCancelled}
            />
          </Col>
        </Row>
        <Row mb={6}>
          <Col w={[12, 6, 6]}>
            <TimePicker
              ref={(el) => (inputRefs.current['timeFrom'] = el)}
              muiTextFieldProps={{
                label: 'Start time',
                id: 'timeFrom',
                variant: 'outlined',
                value: form.timeFrom,
                name: 'timeFrom',
                sx: {
                  borderRadius: '1rem',
                  '& fieldset': {
                    border: 'none',
                  },
                },
                onChange: ({ target }) => handleChange(target),
                error: errors.timeFrom,
                disabled: isCancelled,
              }}
            />
          </Col>
          <Col w={[12, 6, 6]}>
            <TimePicker
              ref={(el) => (inputRefs.current['timeTo'] = el)}
              muiTextFieldProps={{
                label: 'End time',
                id: 'timeTo',
                variant: 'outlined',
                value: form.timeTo,
                name: 'timeTo',
                sx: {
                  borderRadius: '1rem',
                  '& fieldset': {
                    border: 'none',
                  },
                },
                onChange: ({ target }) => handleChange(target),
                error: errors.timeTo,
                disabled: isCancelled,
              }}
            />
          </Col>
        </Row>
        <Row mb={6}>
          <Col w={[12, 12, 12]}>
            <Radio
              row
              name="type"
              label="What type of session will it be?"
              value={form.type}
              error={errors.type}
              onChange={(value) =>
                handleChange({
                  name: 'type',
                  value: value,
                })
              }
              options={[
                {
                  label: 'In-person',
                  value: 'in-person',
                },
                {
                  label: 'Online',
                  value: 'online',
                },
              ]}
              disabled={isCancelled}
            />
          </Col>
        </Row>
        {form.type === 'in-person' ? (
          <>
            <Row mb={6}>
              <Col w={[12, 6, 6]}>
                <BasicInput
                  ref={(el) => (inputRefs.current['addressLine1'] = el)}
                  label="Address Line 1"
                  // placeholder="..."
                  value={form.addressLine1}
                  error={errors.addressLine1}
                  name="addressLine1"
                  handleChange={(value) =>
                    handleChange({ name: 'addressLine1', value })
                  }
                  style={{
                    border: `2px solid ${
                      errors.addressLine1 ? '#D83A2C' : '#333'
                    }`,
                  }}
                  disabled={isCancelled}
                />
              </Col>
              <Col w={[12, 6, 6]}>
                <BasicInput
                  ref={(el) => (inputRefs.current['addressLine2'] = el)}
                  label="Address Line 2"
                  optional
                  // placeholder="..."
                  value={form.addressLine2}
                  error={errors.addressLine2}
                  name="addressLine2"
                  handleChange={(value) =>
                    handleChange({ name: 'addressLine2', value })
                  }
                  style={{
                    border: `2px solid ${
                      errors.addressLine2 ? '#D83A2C' : '#333'
                    }`,
                  }}
                  disabled={isCancelled}
                />
              </Col>
            </Row>
            <Row mb={6}>
              <Col w={[12, 6, 6]}>
                <BasicInput
                  ref={(el) => (inputRefs.current['town'] = el)}
                  label="Town / City"
                  // placeholder="..."
                  value={form.town}
                  error={errors.town}
                  name="town"
                  handleChange={(value) =>
                    handleChange({ name: 'town', value })
                  }
                  style={{
                    border: `2px solid ${errors.town ? '#D83A2C' : '#333'}`,
                  }}
                  disabled={isCancelled}
                />
              </Col>
              <Col w={[12, 6, 6]}>
                <BasicInput
                  ref={(el) => (inputRefs.current['postcode'] = el)}
                  label="Postcode"
                  // placeholder="..."
                  value={form.postcode}
                  error={errors.postcode}
                  name="postcode"
                  handleChange={(value) =>
                    handleChange({ name: 'postcode', value })
                  }
                  style={{
                    border: `2px solid ${errors.postcode ? '#D83A2C' : '#333'}`,
                  }}
                  disabled={isCancelled}
                />
              </Col>
            </Row>
            <Row mb={6}>
              <Col w={[12, 12, 12]}>
                <BasicInput
                  ref={(el) => (inputRefs.current['additionalInfo'] = el)}
                  label="Additional Information"
                  optional
                  // placeholder="..."
                  value={form.additionalInfo}
                  error={errors.additionalInfo}
                  name="additionalInfo"
                  handleChange={(value) =>
                    handleChange({ name: 'additionalInfo', value })
                  }
                  style={{
                    border: `2px solid ${
                      errors.additionalInfo ? '#D83A2C' : '#333'
                    }`,
                  }}
                  disabled={isCancelled}
                />
              </Col>
            </Row>
          </>
        ) : (
          <Row mb={6}>
            <Col w={[12, 12, 12]}>
              <BasicInput
                ref={(el) => (inputRefs.current['link'] = el)}
                label="Meeting Link"
                placeholder="Google meet link, zoom, etc..."
                value={form.link}
                error={errors.link}
                name="link"
                handleChange={(value) =>
                  handleChange({ name: 'link', value: encodeURL(value) })
                }
                style={{
                  border: `2px solid ${errors.link ? '#D83A2C' : '#333'}`,
                }}
                disabled={isCancelled}
              />
            </Col>
          </Row>
        )}

        {isCancelled && (
          <Row>
            <Col w={[12, 12, 12]}>
              <S.HR mb="6" />
              <T.H3 mb="6" color="tertiaryMain">
                This session is canceled
              </T.H3>
            </Col>
          </Row>
        )}
        {isEditMode && !isCancelled && (
          <>
            <Row>
              <Col w={[12, 12, 12]}>
                <S.HR mb="32px" />
                <T.H3 mb="12px">Cancel coaching session</T.H3>
                <T.P mb="12px">
                  If you need to cancel your session you can do so by clicking
                  the button below. Please make sure you send a message to your
                  learner as well to let them know.
                </T.P>
                <T.Link
                  color="tertiaryMain"
                  onClick={() => setDeleteModalType('consent')}
                  // onClick={() => setDeleteModalType('canceled')}
                >
                  Cancel this session
                </T.Link>
                <S.HR my="32px" />
              </Col>
            </Row>
            <ConfirmationSuccessModal
              visible={!!deleteModalType}
              setIsModalVisible={() => setDeleteModalType('')}
              title={
                deleteModalType === 'consent'
                  ? 'Are you sure?'
                  : 'Session Cancelled'
              }
              description={
                deleteModalType === 'consent'
                  ? 'This action cannot be undone. The learner will be notified by email and we recommend sending them a message'
                  : 'The learner will be notified by email. However we recommend sending them a message.'
              }
              confirmText={
                deleteModalType === 'consent' ? 'Confirm' : 'Send message'
              }
              onConfirm={
                deleteModalType === 'consent'
                  ? handleCancel
                  : () => {
                      navigate(
                        navRoutes.COACH.CHAT_SAFEGUARD.replace(
                          ':receiverId',
                          sessionDataToUpdate.learnerId
                        )
                      );
                    }
              }
              onConfirmLoading={isCancelling}
              onConfirmDisabled={isCancelling}
              cancelText={
                deleteModalType === 'consent' ? 'Cancel' : 'Return to dashboard'
              }
              onCancel={() => {
                if (deleteModalType === 'consent') {
                  setDeleteModalType('');
                } else {
                  navigate(navRoutes.COACH.DASHBOARD);
                }
              }}
              httpError={cancelError?.message}
            />
          </>
        )}
        {!isEditMode && (
          <ConfirmAlertWrapper mb={6}>
            <T.P mb={1}>Reminder: Safeguarding</T.P>
            <T.P mb={5}>
              As you arrange your new coaching session, always remember the
              safeguarding steps, such as withholding from sharing personal
              information with each other. If you need a reminder, please
              <T.Link to={navRoutes.COACH.DASHBOARD_SAFEGUARDING}>
                read our Safeguarding advice here.
              </T.Link>
            </T.P>
            <T.H3 weights={'bold'} mb={4}>
              Please make sure to message your learner to confirm they are
              attending
            </T.H3>
            <Checkbox
              label="I understand"
              value={form.confirmed}
              checked={form.confirmed}
              handleChange={(value) => {
                handleChange({
                  name: 'confirmed',
                  value,
                });
              }}
              error={errors.confirmed}
              plain
            />
          </ConfirmAlertWrapper>
        )}
        <Row>
          <Col w={[12, 12, 12]}>
            <T.P color="error">{httpError?.message}</T.P>
          </Col>
        </Row>
        <Col w={[12, 4, 4]}>
          {!isCancelled && (
            <BasicButton
              loading={isLoading}
              handleClick={handleSubmit}
              disabled={submitDisabled && !isEditMode}
            >
              Submit
            </BasicButton>
          )}
        </Col>
      </form>
      <ConfirmationSuccessModal
        variant="default"
        visible={showSuccessModal}
        setIsModalVisible={() => {
          setShowSuccessModal(false);
          navigate(navRoutes.COACH.DASHBOARD);
        }}
        title="Success"
        description="You have successfully scheduled a session with your learner."
        confirmText="Add to calendar"
        addCloseButton
        onConfirm={() => {
          atcb_action({
            name: `Session with ${
              selectLearnerOptions.find((l) => l.value === form.learner)?.label
            }`,
            description: `Meeting Location: ${
              form.type === 'in-person'
                ? `${form.addressLine1}, ${form.town}, ${form.postcode}`
                : form.link?.startsWith('http')
                ? form.link
                : `https://${form.link}`
            }`,
            location:
              form.type === 'in-person'
                ? `${form.addressLine1}, ${form.town}, ${form.postcode}`
                : form.link?.startsWith('http')
                ? form.link
                : `https://${form.link}`,
            startDate: form.date,
            startTime: form.timeFrom,
            endTime: form.timeTo,
            timeZone: 'currentBrowser',
            options: ['Google', 'Outlook.com'],
          });
        }}
      />
    </div>
  );
};
export default ArrangeCoachingSession;
