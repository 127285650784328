import { useRef, useReducer, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  coursesNames,
  navRoutes,
  organisationStatuses,
  paymentPlans,
} from '../../../constants';
import { Col, Row } from '../../../components/Grid';
import * as T from '../../../components/Typography';
import {
  BasicInput,
  DatePicker,
  Dropdown,
  Checkbox,
} from '../../../components/Inputs';
import { BasicButton } from '../../../components/Button';
import { addOrganisation as validate } from '../../../validation/schemas';
import {
  useAddOrganisation,
  useGetOrganisationByIdOrUniqueSlug,
  useUpdateOrganisation,
} from '../../../api-calls/organisations.queries';
import Modal from '../../../components/Modal';
import { BackButton } from 'components/BackButton';
import { ConfirmationSuccessModal, Tip } from 'components';
import makeError from 'api-calls/format-error';
import ExistingEmailConsentModal from './ExistingEmailConsentModal';

const initialState = {
  form: {
    name: '',
    sfAccountId: '',
    firstName: '',
    lastName: '',
    email: '',
    contractEndDate: '',
    paymentPlan: '',
    isWorkingInPrison: false,
    isTpdCourse: false,
    isCmidCourse: false,
  },
  validationErrs: { contactLinks: {} },
  originalEmail: '',
};

function reducer(state, newState) {
  let value = newState;
  if (typeof newState === 'function') {
    value = newState(state);
  }

  return { ...state, ...value };
}

const getCleanEmail = (email) => {
  return email.replace(/ /g, '').toLowerCase();
};

const AddOrganisation = () => {
  const [isUpdateEmailModalVisible, setIsUpdateEmailModalVisible] =
    useState(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const [isConsentModalVisible, setIsConsentModalVisible] = useState({
    show: false,
    organisationNames: [],
  });

  const { id } = useParams();

  const navigate = useNavigate();

  const [state, setState] = useReducer(reducer, initialState);
  const submitAttempt = useRef(false);
  const {
    form: {
      name,
      sfAccountId,
      firstName,
      lastName,
      email,
      contractEndDate,
      paymentPlan,
      isWorkingInPrison,
      isTpdCourse,
      isCmidCourse,
    },
    validationErrs,
    originalEmail,
  } = state;

  const {
    mutateAsync: addOrganisation,
    error: addError,
    isLoading: addOrganisationLoading,
    isError: isAddError,
    isSuccess,
    data: { inviteToken } = {},
  } = useAddOrganisation();

  const {
    mutateAsync: updateOrganisation,
    isLoading: updateOrganisationLoading,
    isSuccess: updateIsSuccess,
    error: updateError,
    isError: isUpdateError,
  } = useUpdateOrganisation({ id });

  const error = addError || updateError;
  const isLoading = addOrganisationLoading || updateOrganisationLoading;
  const isError = isAddError || isUpdateError;

  const { data: organisation = {}, isFetched: organisationLoaded } =
    useGetOrganisationByIdOrUniqueSlug({ id }, { enabled: !!id });

  // Used when updating an existing organisation's courses
  const isRemovingBothCourses = !isTpdCourse && !isCmidCourse;

  const setFormData = (data) =>
    setState((prevState) => ({ form: { ...prevState.form, ...data } }));

  const validateForm = () => {
    try {
      validate(state.form);
      setState({ validationErrs: { hasError: false } });
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setState({
          validationErrs: { ...error.inner, hasError: true },
        });
      }
      return false;
    }
  };

  const onError = (error) => {
    const err = makeError(error);
    if (
      err?.statusCode === 409 &&
      err?.message?.includes('email already exists') &&
      err?.data?.isOrgAdmin
    ) {
      setIsConsentModalVisible({
        show: true,
        organisationNames: err?.data?.organisationNames,
      });
    }
  };
  const handleSubmit = async (e, confirmed, addToExistingAdmin) => {
    e.preventDefault();
    submitAttempt.current = true;

    const isValid = validateForm();
    if (isValid) {
      if (id) {
        if (
          getCleanEmail(email) === getCleanEmail(originalEmail) ||
          confirmed
        ) {
          await updateOrganisation({
            ...state.form,
            email: getCleanEmail(email),
            ...(isRemovingBothCourses && {
              status: organisationStatuses.DEACTIVATED,
            }),
          });
        } else {
          setIsUpdateEmailModalVisible(true);
        }
        setState({ originalEmail: getCleanEmail(email) });
      } else {
        if (!isTpdCourse && !isCmidCourse) {
          setState({
            validationErrs: {
              hasError: false,
              isCmidCourse:
                'You must at least choose one course when adding an organisation',
            },
          });
          return;
        }
        await addOrganisation(
          { ...state.form, email: getCleanEmail(email), addToExistingAdmin },
          { onError }
        );
      }
    }
  };

  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    name,
    sfAccountId,
    firstName,
    lastName,
    email,
    contractEndDate,
    paymentPlan,
    isWorkingInPrison,
    isTpdCourse,
    isCmidCourse,
  ]);

  useEffect(() => {
    if (!isError) return;

    if (error?.statusCode === 409) {
      setState({
        validationErrs: {
          [error?.data.field]: error?.message,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (updateIsSuccess) {
      setIsUpdateEmailModalVisible(false);
      setIsSuccessModalVisible(true);
    }
  }, [updateIsSuccess]);

  useEffect(() => {
    if (organisationLoaded && id) {
      setState({
        form: {
          name: organisation?.name,
          sfAccountId: organisation?.sfAccountId,
          firstName: organisation?.firstName,
          lastName: organisation?.lastName,
          email: organisation?.email,
          contractEndDate: organisation?.contractEndDate,
          paymentPlan: organisation?.paymentPlan,
          isWorkingInPrison: organisation?.isWorkingInPrison,
          isTpdCourse: organisation?.isTpdCourse,
          isCmidCourse: organisation?.isCmidCourse,
        },
        originalEmail: organisation?.email,
      });
    }
  }, [id, organisation, organisationLoaded]);

  const inviteLink = `${
    window.location.origin
  }${navRoutes.ORGANISATION_ADMIN.SIGNUP.replace(':inviteToken', inviteToken)}`;

  useEffect(() => {
    if (isSuccess) {
      navigate(navRoutes.ADMIN.ORGANISATION_ADDED, {
        state: { inviteLink },
      });
    }
  }, [isSuccess, navigate, inviteLink]);

  return (
    <Row jc="center">
      <Col w={[4, 10, 10]}>
        <BackButton link={navRoutes.ADMIN.ORGANISATIONS} />
      </Col>
      <Col w={[4, 10, 10]} mt="6">
        <Row>
          <Col w={[4, 12, 12]}>
            <T.H1 color="neutralMain" weight="bold">
              {id ? 'Edit organisation' : 'Add organisation'}
            </T.H1>
            <T.P mt={4} color="neutral90">
              {id
                ? `Here you can edit the organisation. Please note changing the main account holder details email will change the email address with which the main account will log in.`
                : `Here you can set up organisations to start using the Turning Pages
            platform. When you add the organisation they will receive an invite
            link to create their account.`}
            </T.P>
          </Col>
        </Row>
        <Row mt="6">
          <Col w={[4, 6, 6]} mbM="4">
            <BasicInput
              label="Organisation name"
              placeholder="Organisation name..."
              value={name}
              name="name"
              handleChange={(name) => setFormData({ name })}
              error={validationErrs.name}
            />
          </Col>
          <Col w={[4, 6, 6]}>
            <BasicInput
              label="Salesforce ID"
              placeholder="Salesforce ID..."
              value={sfAccountId}
              name="sfAccountId"
              handleChange={(sfAccountId) => setFormData({ sfAccountId })}
              error={validationErrs.sfAccountId}
            />
          </Col>
        </Row>

        <Row mt={'7'} mtM="6">
          <Col w={[4, 6, 6]}>
            <Checkbox
              plain
              label="This organisation works in prisons"
              checked={isWorkingInPrison}
              name="isWorkingInPrison"
              handleChange={(isWorkingInPrison) => {
                setFormData({ isWorkingInPrison });
              }}
              error={validationErrs.isWorkingInPrison}
            />
          </Col>
        </Row>

        <Row mt={'40px'} mtM="6">
          <Col w={[4, 12, 12]} mb="5">
            <T.H2>Main account holder details</T.H2>
          </Col>

          <Col w={[4, 6, 6]} mbM="4">
            <BasicInput
              label="First name"
              placeholder="First name..."
              value={firstName}
              name="firstName"
              handleChange={(firstName) => setFormData({ firstName })}
              error={validationErrs.firstName}
            />
          </Col>
          <Col w={[4, 6, 6]}>
            <BasicInput
              label="Last name"
              placeholder="Last name..."
              value={lastName}
              name="lastName"
              handleChange={(lastName) => setFormData({ lastName })}
              error={validationErrs.lastName}
            />
          </Col>
          <Col w={[4, 6, 6]} mt={4}>
            <BasicInput
              label="Email address"
              placeholder="Email address..."
              value={email}
              name="email"
              handleChange={(email) => setFormData({ email })}
              error={validationErrs.email}
            />
          </Col>
        </Row>
        <Row mt={'40px'}>
          <Col w={[4, 12, 12]} mb="5">
            <T.H2>Access details</T.H2>
          </Col>

          <Col w={[4, 6, 6]} mbM="4">
            <DatePicker
              label="Contract end date"
              placeholder="Contract end date..."
              value={contractEndDate}
              name="contractEndDate"
              handleChange={(contractEndDate) =>
                setFormData({ contractEndDate })
              }
              validDateLimit={null}
              error={validationErrs.contractEndDate}
            />
          </Col>

          <Col w={[4, 6, 6]}>
            <Dropdown
              label="Payment plan"
              placeholder="Payment plan..."
              selected={paymentPlans.find(
                (_paymentPlan) => _paymentPlan.value === paymentPlan || ''
              )}
              options={paymentPlans}
              name="paymentPlan"
              handleChange={(paymentPlan) => setFormData({ paymentPlan })}
              error={validationErrs.paymentPlan}
            />
          </Col>
        </Row>

        <Row mt={'7'} mtM="6">
          <Col w={[4, 12, 12]} mb="5">
            <T.H2>
              Select authorised courses to give this organisation access
            </T.H2>
          </Col>
          <Col w={[4, 12, 12]}>
            <Checkbox
              plain
              label={coursesNames.TPD}
              checked={isTpdCourse}
              name="isTpdCourse"
              handleChange={(isTpdCourse) => {
                setFormData({ isTpdCourse });
              }}
              error={validationErrs.isTpdCourse}
            />
          </Col>
          <Col w={[4, 12, 12]}>
            <Checkbox
              plain
              label={coursesNames.CMID}
              checked={isCmidCourse}
              name="isCmidCourse"
              handleChange={(isCmidCourse) => {
                setFormData({ isCmidCourse });
              }}
              error={validationErrs.isCmidCourse}
            />
          </Col>
          <Col w={[4, 12, 12]}>
            {id && isRemovingBothCourses && (
              <Tip
                variant="error"
                ShowBoldTipPrefix
                text={
                  'We will still keep hold of all the data, but the organisation and any of their coaches and learners will no longer be able to access that course via the platform'
                }
              />
            )}
          </Col>
        </Row>

        <Row mt="6">
          {error?.message ? (
            <Col w={[4, 12, 12]}>
              <T.P color="error" mb={3}>
                {error?.message}
              </T.P>
            </Col>
          ) : null}
          {validationErrs.hasError && (
            <Col w={[4, 12, 12]}>
              <T.P color="error" mb={3}>
                Please fill all the required fields
              </T.P>
            </Col>
          )}
          <Col w={[4, 12, 12]}>
            <BasicButton
              variant="primary"
              disabled={validationErrs.hasError || isLoading}
              loading={isLoading}
              handleClick={handleSubmit}
              maxWidth="300px"
            >
              <T.P color="white" weight="semi">
                Submit
              </T.P>
            </BasicButton>
          </Col>
        </Row>
      </Col>

      <Modal
        visible={isUpdateEmailModalVisible}
        setIsModalVisible={setIsUpdateEmailModalVisible}
      >
        <Row>
          <Col w={[4, 12, 12]}>
            <T.P size="med" weight="bold" color="white">
              Are you sure?
            </T.P>
            <T.P size="regular" color="white" mt={4} mb={4}>
              By changing their email address you are changing their log in
              details, so please update the main account holder immediately.
            </T.P>

            {updateError?.message && (
              <T.P color="error" mb="-10px">
                {updateError?.message}
              </T.P>
            )}

            <BasicButton
              variant="secondary"
              onClick={(e) => {
                handleSubmit(e, true);
              }}
              mt={4}
            >
              Confirm
            </BasicButton>
            <BasicButton
              variant="tertiary"
              onClick={() => {
                setIsUpdateEmailModalVisible(false);
              }}
              mt={2}
            >
              Cancel
            </BasicButton>
          </Col>
        </Row>
      </Modal>
      <ConfirmationSuccessModal
        visible={isSuccessModalVisible}
        setIsModalVisible={setIsSuccessModalVisible}
        title="Success"
        description={'You have successfully updated this organisation'}
        confirmText="Return to Dashboard"
        onConfirm={() => {
          setIsSuccessModalVisible(false);
          navigate(navRoutes.ADMIN.DASHBOARD);
        }}
        cancelText="Return to Organisations"
        onCancel={() => {
          setIsSuccessModalVisible(false);
          navigate(navRoutes.ADMIN.ORGANISATIONS);
        }}
      />
      <ExistingEmailConsentModal
        email={email}
        isModalVisible={isConsentModalVisible}
        setIsModalVisible={setIsConsentModalVisible}
        handleSubmit={handleSubmit}
      />
    </Row>
  );
};

export default AddOrganisation;
