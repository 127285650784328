import styled from '@emotion/styled';
import { setMargin } from 'helpers';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  flex: 1;
  align-items: center;
`;

export const Grid = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacings[5]} 0;
  background: ${({ theme }) => theme.colors.neutralLight};
  position: absolute;
  left: 0;
`;
export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 11.5px;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  opacity: ${({ disabled }) => (disabled ? '0.3' : '1')};
`;

export const AnswersGridWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: end;
  margin-bottom: 48px;
`;

export const OptionsGrid = styled.div`
  width: 100%;
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 300px;
  margin-bottom: 16px;
`;

export const InstructionWrapper = styled.div`
  width: auto;
  margin: 0 auto;
  margin-top: ${({ theme, mt }) => theme.spacings[mt || 0]};
  flex: ${({ flex }) => flex || 0};
  ${({ theme }) => theme.media.mobile} {
    width: 300px;
  }
`;

export const ButtonWrapper = styled.div`
  width: 300px;
  margin: 0 auto;
  margin-top: ${({ theme, mt }) => theme.spacings[mt || 0]};
  flex: ${({ flex }) => flex || 0};
  display: flex;
  align-items: end;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PreFilledAnswersGridWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacings[1]};
  justify-content: center;
  align-items: center;
  margin: ${({ theme }) => theme.spacings[5]} auto;
  padding: ${({ theme }) => theme.spacings[6]} 0px;
  background: ${({ theme }) => theme.colors.neutralLight};
  max-width: ${(props) => (props.isWithExplainerAudio ? '350px' : 'auto')};
  position: relative;

  :before {
    position: absolute;
    background: ${({ theme }) => theme.colors.neutralLight};
    height: 100%;
    width: 200vw;
    content: '';
    left: -50vw;
    right: 0;
    top: 0;
    z-index: -1;
  }
`;

export const OptionsGridWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: start;
  width: 100%;
`;

export const TextAudioWrapper = styled.div`
  ${setMargin};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ jc }) => jc || 'center'};
`;
