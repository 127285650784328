import { useEffect, useState, useRef, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { string, number, object } from 'yup';
import { createSchema, validate } from '../../../validation';

import { useGetMilestones } from '../../../api-calls/milestones.queries';
import {
  useGetExerciseById,
  useUpdateExercise,
  useCreateExercise,
} from '../../../api-calls/exercises.queries';
import * as T from '../../../components/Typography';
import {
  formTypes,
  mediaInputLabels,
  mediaTypes,
  navRoutes,
} from '../../../constants';

import { BasicInput, Dropdown } from '../../../components/Inputs';
import { BasicButton } from '../../../components/Button';
import MDEditor from '../../../components/MDEditor';
import { MediaKeyInput } from '../../components/MediaKeyInput';
import * as S from '../style';
import SuccessModal from './SuccessModal';
import ExercisesCard from './../../../components/Exercises';

import { Content as CoachNotes } from '../../../pages/Exercises/CoachNotes';
import { Content as ExerciseExplainer } from '../../../pages/Exercises/ExerciseExplainer';
import NavigateExercises from './NavigateExercises';
import handleError from '../../../api-calls/format-error';
import { useExerciseForm } from 'CMS/Providers/ExerciseFormProvider';
import { useSiteSettings } from 'context/site-settings';

const _validateExercise = (data, isTpdCourse) => {
  const REQUIRED_FIELD = 'required field';
  const exerciseSchema = createSchema({
    milestoneId: number()
      .typeError('Select a milestone')
      .required(REQUIRED_FIELD),
    title: string().required(REQUIRED_FIELD),
    titleAudioKey: string().required(REQUIRED_FIELD),
    key: string().required(REQUIRED_FIELD),
    order: number().required(REQUIRED_FIELD),
    ...(isTpdCourse ? { newWords: number().required(REQUIRED_FIELD) } : {}),
    coachNotes: object()
      .shape({
        intro: string().required(REQUIRED_FIELD),
        steps: string().required(REQUIRED_FIELD),
      })
      .required(),
    beforeExercise: object().shape({
      text: string().required(REQUIRED_FIELD),
      instructionsVideoUrl: string()
        .url('Please provide a valid Instructions Video Url')
        .notRequired(),
      textAudioKey: string().required(REQUIRED_FIELD),
    }),
  });

  return validate(exerciseSchema, data);
};

const ViewExercise = ({ createNew }) => {
  const { id } = useParams();
  const createdExerciseId = useRef();
  const { state, updateFormFields, updateFormMetaData, resetForm } =
    useExerciseForm();
  const navigate = useNavigate();
  const location = useLocation();
  const isRedirected = location.state?.isRedirected;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const { data, isSuccess } = useGetExerciseById(
    { id },
    { enabled: !createNew && !isRedirected }
  );

  const { mutateAsync: createExerciseHook, isLoading: isCreatingExercise } =
    useCreateExercise();
  const { mutateAsync: updateExercise, isLoading: isUpdatingExercise } =
    useUpdateExercise();
  const { data: milestones } = useGetMilestones();
  const { isTpdCourse } = useSiteSettings();

  const isLoading = isCreatingExercise || isUpdatingExercise;

  useEffect(() => {
    if (createNew && !isRedirected) resetForm();
  }, [createNew, resetForm, isRedirected]);

  useEffect(() => {
    if (isSuccess && !isRedirected) {
      updateFormFields(
        {
          milestoneId: data.milestoneId,
          title: data.title,
          titleAudioKey: data.titleAudioKey,
          order: data.order,
          newWords: data.newWords,
          key: data.key,
          // coachNotes
          coachNotesIntro: data.coachNotes?.intro?.trim(),
          coachNotesSteps: data.coachNotes?.steps?.trim(),
          // beforeExercise
          beforeExerciseText: data.beforeExercise?.text?.trim(),
          beforeExerciseInstructionsVideoUrl:
            data.beforeExercise?.instructionsVideoUrl?.trim(),
          beforeExerciseTextAudioKey: data.beforeExercise?.textAudioKey,
          beforeExerciseTip: data.beforeExercise?.tip?.trim(),
          beforeExerciseTipAudioKey: data.beforeExercise?.tipAudioKey,
          id: data.id,
        },
        false
      );
    }
  }, [isSuccess, data, updateFormFields, isRedirected]);

  const validateExercise = useCallback(
    (data) => {
      try {
        _validateExercise(data, isTpdCourse);
        updateFormMetaData({ validationErrs: {}, httpError: '' });
        return true;
      } catch (error) {
        updateFormMetaData({ validationErrs: error.inner });
        return false;
      }
    },
    [updateFormMetaData, isTpdCourse]
  );

  useEffect(() => {
    if (isSubmitted) {
      validateExercise({
        milestoneId: state.form.milestoneId,
        title: state.form.title,
        titleAudioKey: state.form.titleAudioKey,
        order: state.form.order,
        newWords: state.form.newWords,
        key: state.form.key,
        coachNotes: {
          intro: state.form.coachNotesIntro,
          steps: state.form.coachNotesSteps,
        },
        beforeExercise: {
          text: state.form.beforeExerciseText,
          instructionsVideoUrl: state.form.beforeExerciseInstructionsVideoUrl,
          textAudioKey: state.form.beforeExerciseTextAudioKey,
          tip: state.form.beforeExerciseTip,
          tipAudioKey: state.form.beforeExerciseTipAudioKey,
        },
      });
    }
  }, [
    isSubmitted,
    state.form.beforeExerciseText,
    state.form.beforeExerciseTextAudioKey,
    state.form.beforeExerciseTip,
    state.form.beforeExerciseTipAudioKey,
    state.form.coachNotesIntro,
    state.form.coachNotesSteps,
    state.form.key,
    state.form.milestoneId,
    state.form.newWords,
    state.form.order,
    state.form.title,
    state.form.titleAudioKey,
    state.form.beforeExerciseInstructionsVideoUrl,
    validateExercise,
  ]);

  // This is used for resetting the route state
  // to re-refetch the exercise data on refresh
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      window.history.replaceState(null, '');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createExercise = () => {
    setIsSubmitted(true);
    const exerciseData = {
      milestoneId: state.form.milestoneId,
      title: state.form.title,
      titleAudioKey: state.form.titleAudioKey,
      order: state.form.order,
      newWords: state.form.newWords,
      key: state.form.key,
      coachNotes: {
        intro: state.form.coachNotesIntro,
        steps: state.form.coachNotesSteps,
      },
      beforeExercise: {
        text: state.form.beforeExerciseText,
        instructionsVideoUrl: state.form.beforeExerciseInstructionsVideoUrl,
        textAudioKey: state.form.beforeExerciseTextAudioKey,
        tip: state.form.beforeExerciseTip,
        tipAudioKey: state.form.beforeExerciseTipAudioKey,
      },
    };
    const isValid = validateExercise(exerciseData);
    if (isValid) {
      const update = Number.isInteger(Number(id));
      const onSuccess = (data) => {
        if (!update) {
          resetForm();
          setIsSubmitted(false);
        }
        setIsUpdate(update);
        updateFormMetaData({ isDirty: false });
        updateFormMetaData({ httpError: '' });
        createdExerciseId.current = data.id;
        setIsModalVisible(true);
        navigate(navRoutes.CMS.VIEW_EXERCISE.replace(':id', data.id), {
          replace: true,
        });
      };

      const onError = (err) => {
        const error = handleError(err);
        updateFormMetaData({
          httpError: error.message,
          validationErrs: {
            key: error?.data?.field === 'key' ? error.message : '',
          },
        });
      };

      if (update) {
        updateExercise(
          {
            ...exerciseData,
            id,
          },
          { onSuccess, onError }
        );
      } else {
        createExerciseHook(
          {
            ...exerciseData,
          },
          { onSuccess, onError }
        );
      }
    }
  };

  // TODO: Validate milestones is not undefined https://github.com/yalla-coop/shannon-trust/issues/3546
  const milestonesOptions = milestones?.map((e) => ({
    label: e.title,
    value: e.id,
  }));

  return (
    <S.Wrapper>
      <S.Form>
        <BasicInput
          label="Exercise ID"
          value={state.form.id}
          handleChange={(id) => updateFormFields({ id })}
          m={{ my: 5 }}
          type="number"
          disabled
          error={state.validationErrs.id}
        />

        <Dropdown
          label="Milestone"
          options={milestonesOptions}
          selected={
            state.form.milestoneId && {
              value: state.form.milestoneId,
              label: milestones?.find((e) => state.form.milestoneId === e.id)
                ?.title,
            }
          }
          search
          handleChange={(milestoneId, a) => {
            updateFormFields({
              milestoneId,
              order: milestones.find((e) => e.id === milestoneId)?.maxOrder + 1,
            });
          }}
          error={state.validationErrs.milestoneId}
          m={{ mb: 5 }}
        />

        <BasicInput
          label="Exercise Order in milestone"
          value={state.form.order}
          handleChange={(order) => updateFormFields({ order })}
          m={{ mt: 5 }}
          type="number"
          disabled
          error={state.validationErrs.order}
        />

        {isTpdCourse && (
          <BasicInput
            label="Number of new words"
            value={state.form.newWords}
            handleChange={(newWords) => updateFormFields({ newWords })}
            m={{ mt: 5 }}
            type="number"
            error={state.validationErrs.newWords}
          />
        )}
        <BasicInput
          label="Key"
          value={state.form.key}
          handleChange={(key) => updateFormFields({ key })}
          m={{ mt: 5 }}
          helper="Get from the exercises spreadsheet, e.g. 'M1_P12'"
          error={state.validationErrs.key}
        />

        <BasicInput
          label="Title"
          value={state.form.title}
          handleChange={(title) => updateFormFields({ title })}
          m={{ mt: 7 }}
          error={state.validationErrs.title}
        />
        <MediaKeyInput
          form={formTypes.EXERCISE}
          type={mediaTypes.TITLE_AUDIO}
          label={`Title ${mediaInputLabels.AUDIO_LABEL}`}
          value={state.form.titleAudioKey}
          handleChange={(titleAudioKey) => updateFormFields({ titleAudioKey })}
          m={{ mt: 4 }}
          error={state.validationErrs.titleAudioKey}
        />

        <T.P mb={2} mt={8} size="large" weight="bold">
          Coach Notes
        </T.P>

        <MDEditor
          value={state.form.coachNotesIntro}
          onChange={(coachNotesIntro) => updateFormFields({ coachNotesIntro })}
          label="Introduction"
          m={{ mt: 5 }}
          error={state.validationErrs.coachNotes?.intro}
        />
        <MDEditor
          value={state.form.coachNotesSteps}
          onChange={(coachNotesSteps) => updateFormFields({ coachNotesSteps })}
          label="Steps"
          m={{ mt: 4 }}
          error={state.validationErrs.coachNotes?.steps}
        />

        <T.P mb={2} mt={5}>
          <T.P mt={8} size="large" weight="bold">
            Before Exercise Page
          </T.P>
          (Explainer page)
        </T.P>
        <MDEditor
          value={state.form.beforeExerciseText}
          onChange={(beforeExerciseText) =>
            updateFormFields({ beforeExerciseText })
          }
          label="Text"
          m={{ mt: 4 }}
          error={state.validationErrs.beforeExercise?.text}
        />
        <MediaKeyInput
          form={formTypes.EXERCISE}
          type={mediaTypes.BEFORE_EXERCISE_TEXT_AUDIO}
          label={`Text ${mediaInputLabels.AUDIO_LABEL}`}
          value={state.form.beforeExerciseTextAudioKey}
          handleChange={(beforeExerciseTextAudioKey) =>
            updateFormFields({ beforeExerciseTextAudioKey })
          }
          m={{ mt: 4 }}
          error={state.validationErrs.beforeExercise?.textAudioKey}
        />
        <BasicInput
          value={state.form.beforeExerciseInstructionsVideoUrl}
          handleChange={(beforeExerciseInstructionsVideoUrl) =>
            updateFormFields({ beforeExerciseInstructionsVideoUrl })
          }
          label="Instructions Video"
          helper="Add the instructional video URL for this exercise."
          m={{ mt: 4 }}
          error={state.validationErrs.beforeExercise?.instructionsVideoUrl}
        />
        <MDEditor
          value={state.form.beforeExerciseTip}
          onChange={(beforeExerciseTip) =>
            updateFormFields({ beforeExerciseTip })
          }
          label="Tip"
          m={{ mt: 7 }}
        />
        <MediaKeyInput
          form={formTypes.EXERCISE}
          type={mediaTypes.BEFORE_EXERCISE_TIP_AUDIO}
          label={`Tip ${mediaInputLabels.AUDIO_LABEL}`}
          value={state.form.beforeExerciseTipAudioKey}
          handleChange={(beforeExerciseTipAudioKey) =>
            updateFormFields({ beforeExerciseTipAudioKey })
          }
          m={{ mt: 4 }}
        />

        <div>
          {
            <T.P mt={5} color="error">
              {state.httpError}
              <T.P mt={5} color="error">
                {!!Object.keys(state.validationErrs)[0] &&
                  'error in one of the fields above'}
              </T.P>
            </T.P>
          }
          {createNew ? (
            <BasicButton onClick={createExercise} loading={isLoading}>
              Submit
            </BasicButton>
          ) : (
            <>
              <BasicButton onClick={createExercise} loading={isLoading}>
                Update
              </BasicButton>
              <BasicButton
                to={navRoutes.CMS.VIEW_EXERCISE_STEPS.replace(':id', id)}
                mt={5}
                disabled={isLoading}
              >
                View steps
              </BasicButton>
            </>
          )}
        </div>
        {!createNew && <NavigateExercises exerciseId={id} />}
      </S.Form>
      <S.Preview>
        <T.P weight="bold" color={'neutralMain'}>
          Exercise card
        </T.P>
        <S.PreviewItem style={{ display: 'flex', justifyContent: 'center' }}>
          <ExercisesCard
            title={state.form.title}
            titleAudioKey={state.form.titleAudioKey}
          />
        </S.PreviewItem>
        <T.P weight="bold" color={'neutralMain'} mt="50px">
          Coach Notes
        </T.P>
        <S.PreviewItem>
          <CoachNotes
            steps={state.form.coachNotesSteps}
            intro={state.form.coachNotesIntro}
            exKey={state.form.key}
          />
        </S.PreviewItem>

        <T.P weight="bold" color={'neutralMain'} mt="50px">
          Before Exercise (Explainer)
        </T.P>
        <S.PreviewItem>
          <ExerciseExplainer
            beforeExercise={{
              text: state.form.beforeExerciseText,
              instructionsVideoUrl:
                state.form.beforeExerciseInstructionsVideoUrl,
              textAudioKey: state.form.beforeExerciseTextAudioKey,
              tip: state.form.beforeExerciseTip,
              tipAudioKey: state.form.beforeExerciseTipAudioKey,
            }}
            disabled
          />
        </S.PreviewItem>
      </S.Preview>
      <SuccessModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        exerciseId={createdExerciseId.current}
        isUpdate={isUpdate}
        setIsSubmitted={setIsSubmitted}
      />
    </S.Wrapper>
  );
};

export default ViewExercise;
