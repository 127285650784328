import { useEffect, useState } from 'react';
import Markdown from '../../../../components/Markdown';
import * as S from './style';
import { UseAudio } from 'Hooks';

const OptionButton = ({
  index,
  option,
  isCorrect,
  handleClick,
  selected,
  imageUrl,
  audioUrl,
  grid,
  bubble,
  mb,
  variant,
  find,
  optionsHaveImages,
}) => {
  const { isLoading, fallbackDisabled, handleAudioClick } = UseAudio({
    audioUrl,
    handleClick: () => handleClick({ option, id: index, isCorrect }),
    fallbackText: option,
  });
  const [isWideImage, setIsWideImage] = useState(false);
  const audioDisabled = isLoading || fallbackDisabled;

  useEffect(() => {
    if (imageUrl) {
      const img = new Image();
      img.onload = () => {
        const aspectRatio = img.width / img.height;
        setIsWideImage(aspectRatio > 2); // threshold for wide images
      };
      img.src = imageUrl;
    }
  }, [imageUrl]);

  return (
    <S.Option
      grid={imageUrl ? !isWideImage && grid : grid}
      onClick={handleAudioClick}
      index={index}
      selected={selected.option + selected.id}
      option={option + index}
      imageUrl={imageUrl}
      mb={mb}
      variant={variant}
      find={find}
      leftPadding={optionsHaveImages ? false : /\s/.test(option)}
      disabled={audioUrl && audioDisabled}
      optionsHaveImages={optionsHaveImages}
    >
      {imageUrl ? (
        <img
          src={imageUrl}
          alt={option}
          style={{
            width: '100%',
            height: '100%',
            objectFit: optionsHaveImages ? 'contain' : 'cover',
          }}
        />
      ) : (
        <Markdown
          text={option}
          customStyles={{
            p: {
              size: bubble ? 'medLarge' : 'med',
              ta: /\s/.test(option) ? 'left' : 'center',
              color:
                selected?.option && selected.option === option
                  ? 'neutralSurface'
                  : '',
            },
          }}
        />
      )}
    </S.Option>
  );
};

export default OptionButton;
